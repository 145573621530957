import firebase from 'firebase/app'
import { FirestoreProvider } from './base/react-firestore'
import React from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles';

import { 
  Grid,
} from '@material-ui/core';

import ErrorBoundary from './misc/ErrorBoundary'
import Routes from './Routes'
import Layout from './layout/Layout'

import '../styles/global'
import 'react-toastify/dist/ReactToastify.css'

//import LogRocket from 'logrocket';
import theme from '../theme';

import ItemListerFacebook from './account/ItemListerFacebook'
import ItemListerMercari from './account/ItemListerMercari'
import ItemListerPoshmark from './account/ItemListerPoshmark'

import ItemDelisterMercari from './account/ItemDelisterMercari'
import ItemDelisterPoshmark from './account/ItemDelisterPoshmark'


import Lister from './lister/Lister'

import { ToastContainer, toast } from './toast'
import { QuickImporterAlert, QuickImporter } from './posts/import/QuickImporter'

import CloseIcon from '@material-ui/icons/Close'
import Store from '../store'

//LogRocket.init('hs9d8q/closetpilot-dev');

// toast(<Lister />, {
//   position: 'bottom-left',
//   autoClose: false,
//   closeButton: <CloseIcon />,
//   hideProgressBar: false,
//   closeOnClick: false,
//   pauseOnHover: true,
//   draggable: true,
// })
  
const App = () => (
  <ThemeProvider theme={theme}>
    <FirestoreProvider firebase={firebase}>
      <BrowserRouter>
        <ErrorBoundary>
          <Layout>
            <Route path="/" component={ScrollToTop}/>
            <Route path="/" component={Analytics}/>
            <Route path="/" component={ItemListerFacebook}/>
            <Route path="/" component={ItemListerMercari}/>
            <Route path="/" component={ItemListerPoshmark}/>
            <Route path="/" component={ItemDelisterMercari}/>
            <Route path="/" component={ItemDelisterPoshmark}/>
            <Routes />
            <ToastContainer />        
            <Grid container justify='center' alignItems='center'>     
              <Grid item md={12} lg={12}>
                <QuickImporterAlert useSnackbar />
              </Grid>
            </Grid>
            <Route path="/" component={QuickImporter}/> {/* Putting at bottom prevents visual jumping on import*/}
          </Layout>
        </ErrorBoundary>
      </BrowserRouter>
    </FirestoreProvider>
  </ThemeProvider>
)

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {

    if (
      this.props.location &&
      this.props.location.pathname === '/' &&
      prevProps.location &&
      prevProps.location.pathname &&
      prevProps.location.pathname.indexOf('/item/') > -1
    ) {
      // Default to saved scroll position
      
      // const yScroll = Store.getVerticalScrollPosition()

      // console.log('Returning to vertical scroll position:', yScroll)

      // setTimeout(() => {
      //   window.scrollTo(0, yScroll)
      // }, 0)
    } else if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return null
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({location}) => {
  const page = location.pathname + location.search
  ReactGA.set({ page })
  ReactGA.pageview(page)
  
  return null
}

export default App

// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'

import { 
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import styles from '../form/ItemFormStyles'
import ItemFormHeader from '../ItemFormHeader'
import ItemFormListedBanner from '../ItemFormListedBanner'
import FormFillIframeButton from '../form/FormFillIframeButton'

import ItemFormMerchantValues from './helpers/ItemFormMerchantValues'
import ItemSidebarMerchantListItem from '../ItemSidebarMerchantListItem'

import ItemFormImages from '../form/ItemFormImages'
import ItemFormInputCategories from '../form/ItemFormInputCategoriesRecursive'
import ItemFormInputPrice from '../form/ItemFormInputPrice'
import ItemFormInputTitle from '../form/ItemFormInputTitle'
import ItemFormInputDescription from '../form/ItemFormInputDescription'

import FormValidationErrors from '../../base/FormValidationErrors'
import FormError from '../../base/FormValidationError'

import facebookCategories from '../../data/categories/facebook-categories'

import updateItem from '../../../actions/updateItem'
import Store from '../../../store'
import { Message, EE, Events } from '../../../message'

import ItemListerFacebook from '../../account/ItemListerFacebook'

import $ from 'jquery'

import {
  ListingJoyExternalEvent,
  FacebookEvent,
} from '../../../constants'

class ItemFormTradesy extends React.Component {

  constructor(props) {
    super(props)

    if (props.item) {
      this.state = {
        item: props.item,
        initialFiles: props.item.imageURLs,
      }
    } else {
      this.state = {}
    }

    this._updateItem = this._updateItem.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmitList = this.onSubmitList.bind(this)
    this.onSubmitDelist = this.onSubmitDelist.bind(this)
    this.onReceiveListingItemResponse = this.onReceiveListingItemResponse.bind(this)
    this.onReceiveDelistingItemResponse = this.onReceiveDelistingItemResponse.bind(this)

    this.merchantValues = new ItemFormMerchantValues(props.item, props.merchantName)
    this.formRef = React.createRef()

    this.TITLE_MAX_CHARS = 100
    this.DESC_MAX_CHARS = 3000
  }

  componentDidMount() {
    EE.addListener(Events.ItemListerFacebook.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.addListener(Events.ItemSidebarMerchantListItem.DELIST_FACEBOOK, this.onSubmitDelist)

    document.addEventListener(FacebookEvent.RECEIVE.DELIST_LISTING, this.onReceiveDelistingItemResponse)
    if (this.formRef.current) {
      this.onFormChange()
    }
  }

  componentWillUnmount() {
    console.log('FB form will unmount')

    EE.removeListener(Events.ItemListerFacebook.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.removeListener(Events.ItemSidebarMerchantListItem.DELIST_FACEBOOK, this.onSubmitDelist)

    document.removeEventListener(FacebookEvent.RECEIVE.DELIST_LISTING, this.onReceiveDelistingItemResponse)
  }

  componentDidUpdate() {
    const { hidden, item } = this.props
    if (!hidden && item && !item.listed) {
      EE.emitEvent(Events.ItemListerFacebook.INITIALIZE)
    }
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  onReceiveListingItemResponse = (data, updatedValues = null) => {
    console.log('In react received data for listing item!!', data)

    // No data or function has timed out
    if (!data || !this.state.listing) {
      return this.setState({
        listing: false,
      })
    }

    const errorFragments = (data.errors || []).map((error, idx) => <FormError nonBlocking={true} key={idx}>{error.message}</FormError>)
    if (errorFragments.length > 0) {
      return this.setState({
        listing: false,
        errorFragments: errorFragments,
      })
    }

    if (updatedValues) {
      this._updateItem(updatedValues, {
        listing: false,
        errorFragments: [],
      }, true)
      console.log('Updated item!')
    }
  }

  onReceiveDelistingItemResponse = (data) => {
    console.log('Received delisting item data', data)

    if (!data || !data.success) {
      return this.setState({
        delisting: false,
      })      
    }

    updateItem(this.props.item.id, {
      merchants: {
        facebook: {
          listed: false,
        },
      }
    }).then(updatedValues => {

      this._updateItem(updatedValues, {
        delisting: false,
        errorFragments: [],
      }, true)
      console.log('Delisted facebook item!')
    }).catch(error => {
      console.error('Error after delisting: ', error)
      this.setState({
        delisting: false,
        errorFragments: [<FormError nonBlocking={true} key='error'>{error.message}</FormError>],
      })
    })
  }

  _updateItem(updatedValues, additionalStateValues = {}, notify = false) {
    const updatedItem = this.merchantValues.getUpdatedItem(updatedValues, this.state.item)

    if (notify) {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, { 
        item: updatedItem,
      })
    }

    this.merchantValues = new ItemFormMerchantValues(updatedItem, this.props.merchantName)      
    this.setState({ 
      item: updatedItem,
      saving: false,
      ...additionalStateValues,
    })
  }

  _formErrorFragments() {
    const data = this._itemData()
    const itemData = this.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props
    const categoryPath = ((data.merchants[merchantName] || {}).overrides || {}).categoryPath
    return [
      !(Store.getUserDetailsFacebook() || {}).username && <FormError key={'connection'} selector={`#connection`}>Facebook is not connected. Go to <Link to='/settings'>Merchant Connections</Link> to link your Facebook account.</FormError>,
      (!itemData.imageURLs || itemData.imageURLs.length === 0) && <FormError key={'images'} selector={`#${merchantName}images`}>Must supply at least one image</FormError>,
      (!itemData.title || itemData.title.length === 0) && <FormError key={'title'} selector={`#${merchantName}title`}>Must supply a title</FormError>,
      (!itemData.description || itemData.description.length === 0) && <FormError key={'description'} selector={`#${merchantName}description`}>Must supply a description</FormError>,
      (!categoryPath || categoryPath.length === 0) && <FormError key={'category'} selector={`#${merchantName}categoryPath`}>Must fill out category</FormError>,
      (!itemData.price) && <FormError key={'price'} selector={`#${merchantName}price`}>Must fill out listing price</FormError>,
    ]
    .filter(Boolean)    
  }

  onSubmit = event => {
    event.preventDefault()
    const errorFragments = this._formErrorFragments()

    this.setState({
      saving: true,
      errorFragments: errorFragments,
    })

    const data = this._itemData()
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitList = () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })      
      return
    }
    this.setState({
      listing: true,
    })

    const data = this._itemData()
    const itemData = this.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props
    const categoryPath = data.merchants[merchantName].overrides.categoryPath
    const eventData = {
      id: itemData.id,
      imageURLs: itemData.imageURLs,
      title: itemData.title,
      description: itemData.description,
      price: itemData.price,
      categoryName: (facebookCategories[String(categoryPath[0])] || {}).display,
    }

    EE.emitEvent(Events.ItemListerFacebook.CREATE_LISTING, [eventData])

    // Also save:
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitDelist = () => {
    this.setState({
      delisting: true,
    })

    // We don't support automatic delisting so do it manually
    this.onReceiveDelistingItemResponse({ success: true })
  }

  // onFormFillIframeLoaded = () => {
  //   let itemData = this.merchantValues.getFlattenedData(this._itemData())

  //   if (itemData.categoryPath && itemData.categoryPath.length > 0) {
  //     itemData.categoryName = facebookCategories[String(itemData.categoryPath[0])]

  //     console.log('Category name: ', itemData.categoryName)
  //   }
  //   const event = new CustomEvent(FacebookEvent.SEND.FILL_NEW_LISTING, {
  //     detail: {
  //       data: itemData,
  //     }
  //   })
  //   document.dispatchEvent(event)  
  //   console.log(`Dispatching an ${FacebookEvent.SEND.FILL_NEW_LISTING} event with in ItemFormTradesy`) 
  // }

  _itemData() {
    const elements = this.formRef.current.elements
    const { item } = this.state
    const {
      title, 
      description,
      price,
    } = elements

    const merchantValues = {
      title: title.value,
      description: description.value,
      categoryPath: this.merchantValues.getCategoryPathNew(elements),
      price: +price.value,
    }
    const overrides = this.merchantValues.buildOverrides(merchantValues, item)

    return {
      merchants: {
        facebook: {
          type: "listing",
          listedID: null,
          overrides: overrides,
        },
      },
    };
  }

  render() {
    const { classes, hidden } = this.props
    const { item, errorFragments } = this.state

    return (
      <Paper className={`${classes.root} ${classes.rootTradesy}`} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid 
          container 
          spacing={1}
          direction="row"
        >
          <ItemFormHeader 
            merchantName={this.props.merchantName}
            item={item}
          />
          <form ref={this.formRef} onSubmit={this.onSubmit.bind(this)}>

            { 
              this.merchantValues.getMerchantListingValue('listed', false) ?
              <ItemFormListedBanner 
                merchantName={this.props.merchantName}
                item={item}
                isListing={this.state.listing}
                isDelisting={this.state.delisting}
                onList={this.onSubmitList}
                onDelist={this.onSubmitDelist}
              /> : null
            }

            <Grid container direction="row" className={classes.row}>
              <Grid item xs={12} sm={12} md={3}>
                <Typography variant="h2" className={classes.h2}>
                  Item Photos
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className={classes.rowContent}>
                <ItemFormImages 
                  merchantName={this.props.merchantName}
                  imageURLs={this.merchantValues.get('imageURLs', [])} 
                />
              </Grid>
            </Grid>


            <Grid container direction="row" className={classes.row}>
              <Grid item xs={12} sm={12} md={3}>        
                <Typography variant="h2" className={classes.h2}>
                  Item Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className={classes.rowContent}>
                <ItemFormInputTitle
                  maxCharacters={this.TITLE_MAX_CHARS}
                  defaultTitle={this.merchantValues.get('title', '')}
                  onChange={this.onFormChange}
                />
              </Grid>
              <Grid item md={3} />
              <Grid item xs={12} sm={12} md={9}>
                <ItemFormInputDescription
                  maxCharacters={this.DESC_MAX_CHARS}
                  defaultDescription={this.merchantValues.get('description', '')}
                  onChange={this.onFormChange}
                />
              </Grid>

              <Grid container direction="row" className={classes.row}>
                <Grid item xs={12} sm={12} md={3}>        
                  <Typography variant="h2" className={classes.h2}>
                    Category
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} className={classes.rowContent}>
{/*                  <ItemFormInputCategories 
                    merchantName={this.props.merchantName}
                    item={item}
                    defaultCategoryPath={this.merchantValues.get('categoryPath', [])}
                    onChange={this.onFormChange}
                    hidden={this.props.hidden}
                  />*/}
                </Grid>
              </Grid>{/* Category */}

              <Grid container direction="row" className={classes.row}>
                <Grid item xs={12} sm={12} md={3}>        
                  <Typography variant="h2" className={classes.h2}>
                    Pricing
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} className={classes.rowContent}>
                  <Grid container direction="row">
                    <InputLabel>Price</InputLabel>
                  </Grid>
                  <Grid container direction="row">
                    <ItemFormInputPrice
                      defaultPrice={this.merchantValues.get('price', '')}
                      onChange={this.onFormChange}
                    />
                  </Grid>
                </Grid>
              </Grid>{/* Pricing */}

              { errorFragments && errorFragments.length > 0 &&
                <FormValidationErrors
                  errorFragments={errorFragments}
                />
              }

            </Grid>

            <ItemFormListedBanner 
              merchantName={this.props.merchantName}
              item={item}
              onList={this.onSubmitList}
              onDelist={this.onSubmitDelist}
              isListing={this.state.listing}
              isDelisting={this.state.delisting}
              isListingDisabled={
                errorFragments && 
                errorFragments.length > 0 &&
                errorFragments.find(f => !f.props || !f.props.nonBlocking)
              }
              saveButton={
                <Button disabled={this.state.saving} variant="contained" color="primary" type="submit">
                  {this.state.saving ? 'Saving..' : 'Save item' }
                </Button>
              }
            />

            {/*<FormFillIframeButton
              merchantName={this.props.merchantName}
              onFormFillIframeLoaded={this.onFormFillIframeLoaded.bind(this)}
            />*/}

{/*            { this.state.delisting ? 
              <Iframe 
                id={`iframe-delist-${this.props.merchantName}`}
                className="iframe-delist"
                url={''}
                width={500} 
                height={800}
                display="initial" 
                position="relative"
              /> : null
            }*/}
          </form>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(ItemFormTradesy)

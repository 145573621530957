import firebase from 'firebase/app'
import 'firebase/firestore'
import ReactGA from 'react-ga'

const fetchCategorySpecifics = (merchantName, categoryID, timeoutMs = 8000) => {
  if (!categoryID) {
    return Promise.resolve(null)
  }

  ReactGA.event({
    category: 'Category Specifics',
    action: 'Fetch Category Specifics',
  })

  switch (merchantName) {
    case 'ebay':
    case 'etsy':
    case 'mercari':
    case 'poshmark':
      break
    case 'facebook':
      // no specifics for merchant
      return Promise.resolve(null)
    default:
      console.error('Invalid merchantName provided in fetchCategorySpecifics: ', merchantName)
      return Promise.resolve(null)
  }

  const capitalizedMerchantName = merchantName.charAt(0).toUpperCase() + merchantName.substring(1)
  console.log('Fetching for merchant name: ', capitalizedMerchantName)

  const firestorePromise = new Promise((resolve, reject) => {
    firebase.firestore()
      .collection(`categorySpecifics${capitalizedMerchantName}`)
      .doc(String(categoryID))
      .get()
      .then(doc => {
        if (!doc || !doc.exists || doc.data().message === 'error') {
          return resolve(null)
        }
        return resolve(formatCategorySpecifics(doc.data()))
      })
      .catch( error => {
        console.error(`Couldn't get the category ${categoryID} for merchant ${merchantName}: ${error.message}`)
        return reject(error)
      })
  })
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(resolve, timeoutMs, null)
  })

  return Promise.race([firestorePromise, timeoutPromise])
}

// Build a nested data structure instead of the default flat structure
// If there are parent/child dependencies for the inputs, build that into the data structure
const formatCategorySpecifics = (data) => {

  let result = {}
  let nestedCategoriesData = {} // parentValueID => { id: categorySpecificId, data: data }

  for (let [categorySpecificId, categoryData] of Object.entries(data)) {
    let rules = categoryData.validationRules || {}

    if (rules && rules.parentValueID && rules.parentValueID.length > 0) {
      // First pass, do not add these to main category object
      // Add them on the next pass as children
      // Currently we assume only one level of nesting
      nestedCategoriesData[rules.parentValueID] = {
        id: categorySpecificId,
        data: { ...categoryData }, // clone
      }
    } else {
      result[categorySpecificId] = { ...categoryData } // clone
    }
  }

  for (let [categorySpecificId, categoryData] of Object.entries(result)) {

    // This is O(n^2). Make more efficient if this is an issue
    // Second pass
    for (let [scaleId, scaleData] of Object.entries(categoryData.scales)) {

      for (let [optionKey, optionData] of Object.entries(scaleData.options)) {
        const child = nestedCategoriesData[optionData.id]
        if (child) {
          // If a nested category exists for this ID, add it to the nested result data
          if (!result[categorySpecificId].scales[scaleId].options[optionKey].child) {
            // initialize object if it doesn't exist
            result[categorySpecificId].scales[scaleId].options[optionKey].child = {}
          }

          result[categorySpecificId].scales[scaleId].options[optionKey].child[child.id] = { ...child.data } // clone
        }
      }
    }
  }
  return result
}

export default fetchCategorySpecifics

// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'

import {
  Button,
  Grid,
  FormHelperText,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import styles from '../form/ItemFormStyles'

import TextFieldWithSavedNote from '../form/TextFieldWithSavedNote'

import ItemFormSectionHeaderPhotos from '../form/headers/ItemFormSectionHeaderPhotos'
import ItemFormSectionHeaderDetails from '../form/headers/ItemFormSectionHeaderDetails'
import ItemFormSectionHeaderCategory from '../form/headers/ItemFormSectionHeaderCategory'
import ItemFormSectionHeaderPackage from '../form/headers/ItemFormSectionHeaderPackage'
import ItemFormSectionHeaderPrice from '../form/headers/ItemFormSectionHeaderPrice'

import ItemFormHeader from '../ItemFormHeader'
import ItemFormListedBanner from '../ItemFormListedBanner'
import ItemFormImages from '../form/ItemFormImages'
import ItemFormInputBrand from '../form/ItemFormInputBrand'
import ItemFormInputCategories from '../form/ItemFormInputCategoriesRecursive'
import ItemFormInputColor from '../form/ItemFormInputColor'
import ItemFormInputCondition from '../form/ItemFormInputCondition'
import ItemFormInputDescription from '../form/ItemFormInputDescription'
import ItemFormInputDimensions from '../form/ItemFormInputDimensions'
import ItemFormInputPrice from '../form/ItemFormInputPrice'
import ItemFormInputSku from '../form/ItemFormInputSku'
import ItemFormInputTitle from '../form/ItemFormInputTitle'
import ItemFormInputWeight from '../form/ItemFormInputWeight'

import ItemFormEbayPricing from '../form/ebay/ItemFormEbayPricing'
import ItemFormEbayShipping from '../form/ebay/ItemFormEbayShipping'

import ItemFormMerchantValues from './helpers/ItemFormMerchantValues'
import FormValidationErrors from '../../base/FormValidationErrors'
import FormError from '../../base/FormValidationError'
import CategoryHelpers from '../merchants/helpers/categoryHelpers'

import fetchUser from '../../../actions/fetchUser'
import delistEbay from '../../../actions/delistEbay'
import listEbay from '../../../actions/listEbay'
import updateItem from '../../../actions/updateItem'

import Store from '../../../store'
import Merchant from '../../../merchant'
import { Message, EE, Events } from '../../../message'
import {
  ListingJoyExternalEvent,
} from '../../../constants'


const MAX_CHARACTERS_TITLE = 80

class ItemFormEbay extends React.Component {

  constructor(props) {
    super(props)

    this.userInfo = Store.getUserInfo() || {}

    console.log('Locally stored user info: ', this.userInfo)

    const ebayPolicies = this.userInfo.ebayPolicies || {}
    const ebayConnectionExpiration = this.userInfo.ebayConnectionExpiration || null

    console.log('ebay policies in constructor: ', ebayPolicies)
    if (props.item) {
      this.state = {
        item: props.item,
        initialFiles: props.item.imageURLs,
        errorFragments: [],
        ebayPolicies: ebayPolicies,
        ebayConnectionExpiration: ebayConnectionExpiration,
      }
    } else {
      this.state = {
        ebayPolicies: {},
        ebayConnectionExpiration: ebayConnectionExpiration,
      }
    }

    this.merchantValues = new ItemFormMerchantValues(props.item, props.merchantName)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmitList = this.onSubmitList.bind(this)
    this.onSubmitDelist = this.onSubmitDelist.bind(this)
    this.onSubmitUpdate = this.onSubmitUpdate.bind(this)

    this._updateItem = this._updateItem.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)
    this._itemData = this._itemData.bind(this)

    this.formRef = React.createRef()
  }

  async componentDidMount() {
    console.log('Fetching shipping profs')

    EE.addListener(Events.ItemSidebarMerchantListItem.DELIST_EBAY, this.onSubmitDelist)

    if (this.formRef.current) {
      this.onFormChange()
    }

    if (!this.state.ebayPolicies || !this.state.ebayConnectionExpiration) {
      const userInfo = await fetchUser()
      this.userInfo = userInfo || {}

      const ebayPolicies = this.userInfo.ebayPolicies || {}
      const ebayConnectionExpiration = this.userInfo.ebayConnectionExpiration || null

      console.log('Got ebay profiles', ebayPolicies)

      this.setState({
        ebayPolicies,
        ebayConnectionExpiration,
      }, () => {
        // Need to wait for the state update before updating form
        this.setState({
          errorFragments: this._formErrorFragments(),
        })
      })
    }
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemSidebarMerchantListItem.DELIST_EBAY, this.onSubmitDelist)
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  _updateItem(updatedValues, additionalStateValues = {}, notify = false) {
    const updatedItem = this.merchantValues.getUpdatedItem(updatedValues, this.state.item)

    if (notify) {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, {
        item: updatedItem,
      })
    }
    this.merchantValues = new ItemFormMerchantValues(updatedItem, this.props.merchantName)
    this.setState({
      item: updatedItem,
      saving: false,
      ...additionalStateValues
    })
  }

  _itemData() {
    const { item } = this.state
    const elements = this.formRef.current.elements
    const {
      title,
      description,
      condition,
      colorPrimary,
      sku,
      upc,

      // shipping
      shippingType,
      allowBestOffer,
      freeShipping,
      handlingTime,
      shippingService,
      paypalEmail,
      acceptReturns,

      shipsFrom,

      // business policies
      shippingPolicy,
      paymentPolicy,
      returnPolicy,

      // pricing / auction
      duration,
      pricingFormat,
      startingPrice,
      buyItNowPrice,
      offerMinimumAccept,
      offerMaximumDecline,
    } = elements

    // Consider doing a "deep diff" of the original props
    // https://stackoverflow.com/a/25651677
    const merchantValues = {
      title: title.value,
      description: description.value,
      brand: this.merchantValues.getBrand(elements),
      categoryPath: this.merchantValues.getCategoryPathNew(elements),
      condition: condition.value,
      colorPrimary: colorPrimary.value,
      sku: sku.value,
      upc: upc.value,
      weight: this.merchantValues.getWeight(elements),
      dimensions: this.merchantValues.getDimensionsArray(elements),
    }
    const overrides = this.merchantValues.buildOverrides(merchantValues, item)
    return {
      merchants: {
        ebay: {
          type: "listing",
          marketplaceSpecifics: {
            // shipping
            shippingType: shippingType ? shippingType.value : null,
            allowBestOffer: allowBestOffer ? allowBestOffer.checked : null,
            freeShipping: freeShipping ? freeShipping.checked : null,
            handlingTime: handlingTime ? handlingTime.value : null,
            shippingService: shippingService ? shippingService.value : null,
            paypalEmail: paypalEmail ? paypalEmail.value : null,
            acceptReturns: acceptReturns ? acceptReturns.value : null,

            // business policies
            shippingPolicy: shippingPolicy ? shippingPolicy.value : null,
            paymentPolicy: paymentPolicy ? paymentPolicy.value : null,
            returnPolicy: returnPolicy ? returnPolicy.value : null,

            // pricing / auction
            duration: duration ? duration.value : null,
            pricingFormat: pricingFormat ? pricingFormat.value : null,
            startingPrice: startingPrice ? +startingPrice.value : null,
            buyItNowPrice: buyItNowPrice ? +buyItNowPrice.value : null,
            offerMinimumAccept: offerMinimumAccept ? offerMinimumAccept.value : null,
            offerMaximumDecline: offerMaximumDecline ? offerMaximumDecline.value : null,

            shipsFrom: shipsFrom ? shipsFrom.value : null,
            categorySpecifics: this.merchantValues.getCategorySpecificsValues(elements),
          },
          overrides: overrides,
        },
      },
    };
  }

  _formErrorFragments() {
    const data = this._itemData()
    const itemData = this.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props
    const { ebayPolicies = {}, ebayConnectionExpiration } = this.state
    const categoryPath = ((data.merchants[merchantName] || {}).overrides || {}).categoryPath
    const merchantNameFormatted = Merchant.merchantNameFormatted(merchantName)
    const now = Math.floor((new Date()).getTime() / 1000)

    // For now, only support users who use payment policies
    const hasBusinessPolicies = ebayPolicies && Object.keys(ebayPolicies).length > 0

    console.log('Has business policies:', hasBusinessPolicies, itemData)
    // TODO
    return [

      !ebayConnectionExpiration && <FormError key={'connection'} selector={`#connection`}>{merchantNameFormatted} is not connected. Go to <Link to='/settings'>Merchant Connections</Link> to link your {merchantNameFormatted} account.</FormError>,
      ebayConnectionExpiration && ebayConnectionExpiration < now && <FormError key={'connection'} selector={`#connection`}>{merchantNameFormatted}'s connection needs to be refreshed. Go to <Link to='/settings'>Merchant Connections</Link> to reconnect your {merchantNameFormatted} account.</FormError>,

      (!hasBusinessPolicies) && <FormError key={'businessPolicies'}>Must <a target="_blank" href="https://www.ebay.com/help/policies/business-policy/business-policies">opt in to eBay business policies</a> in order to list</FormError>,

      (!itemData.imageURLs || itemData.imageURLs.length === 0) && <FormError key={'images'} selector={`#${merchantName}images`}>Must supply at least one image</FormError>,
      (!itemData.title || itemData.title.length === 0) && <FormError key='title1' selector={`#${merchantName}title`}>Must supply a title</FormError>,
      (itemData.title && itemData.title.length > MAX_CHARACTERS_TITLE) && <FormError key={'title2'} selector={`#${merchantName}title`}>Title must be 40 characters or less</FormError>,
      (!itemData.description || itemData.description.length === 0) && <FormError key={'description'} selector={`#${merchantName}description`}>Must supply a description</FormError>,

      (!categoryPath || categoryPath.length === 0) && <FormError key={'category'} selector={`#${merchantName}categoryPath`}>Must fill out category</FormError>,
      (categoryPath && categoryPath.length > 0 && !CategoryHelpers.isCategoryPathCompleteForMerchant(categoryPath, merchantName)) && <FormError key={'category'} selector='#categoryPath'>Must finish filling out category</FormError>,
      (!itemData.weight || (itemData.weight.lb === 0 && itemData.weight.oz === 0)) && <FormError key={'weight1'} selector={`#${merchantName}weightLbs`}>Must fill out weight</FormError>,
      (!itemData.price) && <FormError key={'price'} selector={`#${merchantName}price`}>Must fill out list price</FormError>,

      (!itemData.brand || itemData.brand.length === 0) && <a key={'brand'} selector={`#${merchantName}brand`}>Must supply a brand</a>,
      (itemData.condition === undefined) && <a key={'condition'} selector={`#${merchantName}condition`}>Must supply the item's condition</a>,
      (!itemData.shipsFrom || itemData.shipsFrom.length < 5) && <a key={'condition'} selector={`#${merchantName}condition`}>Must supply a shipping zip code</a>,
      (itemData.colorPrimary === undefined) && <a key={'colorPrimary'} selector={`#${merchantName}colorPrimary`}>Must supply a primary color</a>,
      (!itemData.dimensions || itemData.dimensions.filter(d => +d === 0).length > 0) && <FormError key={'dimensions'} selector={`#${merchantName}dimensions`}>Must supply all dimensions</FormError>,

      // biz policies
      (hasBusinessPolicies && !itemData.paymentPolicy) && <FormError key={'paymentPolicy'}>Must select a payment policy</FormError>,
      (hasBusinessPolicies && !itemData.returnPolicy) && <FormError key={'returnPolicy'} selector={`#${merchantName}dimensions`}>Must select a return policy</FormError>,
      (hasBusinessPolicies && !itemData.shippingPolicy) && <FormError key={'shippingPolicy'} selector={`#${merchantName}dimensions`}>Must select a shipping policy</FormError>,

      // non-biz policies
      //(!hasBusinessPolicies && !itemData.shippingType) && <FormError key={'shippingType'}>Must select a shipping type</FormError>,
      //(!hasBusinessPolicies && !itemData.handlingTime) && <FormError key={'handlingTime'}>Must select a handling time</FormError>,
      //(!hasBusinessPolicies && !itemData.paypalEmail) && <FormError key={'paypalEmail'}>Must provide a PayPal email</FormError>,
      // pricing
      (!itemData.duration) && <FormError key={'paymentPolicy'}>Must select a duration for your listing.</FormError>,
      (itemData.pricingFormat === 'Chinese' && (!itemData.startingPrice && itemData.startingPrice !== 0))  && <FormError key={'paymentPolicy'}>Must provide a starting price for auction-based listings</FormError>,
    ].filter(Boolean)
  }

  onSubmit = event => {
    console.log('On submit')
    event.preventDefault()

    const errorFragments = this._formErrorFragments()
    this.setState({
      saving: true,
      errorFragments: errorFragments,
    })

    let data = this._itemData()
    const { merchantName } = this.props

    // Add SKU if needed
    if (!data.merchants[merchantName]) {
      data.merchants[merchantName] = {}
      if (!data.merchants[merchantName].overrides) {
        data.merchants[merchantName].overrides = {}
      }
      if (data.merchants[merchantName].overrides.sku) {
        // Generate a SKU if it doesn't exist
        data.merchants[merchantName].overrides.sku = String(Math.floor(10000000 + Math.random() * 90000000))
      }
    }


    console.log('On submit data: ', data)
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  listItem = async () => {
    const data = this._itemData()

    // Save item in parallel to listing it:
    this.props.onSubmit(data)

    const itemData = this.merchantValues.getFlattenedData(data)

    const {
      id,

      acceptReturns,
      allowBestOffer,
      buyItNowPrice,
      duration,
      freeShipping,
      handlingTime,
      offerMaximumDecline,
      offerMinimumAccept,
      paymentPolicy,
      paypalEmail,
      pricingFormat,
      returnPolicy,
      shippingPolicy,
      shippingType,
      shipsFrom,
      startingPrice,
      upc,

      brand,
      categoryPath,
      categorySpecifics,
      condition,
      description,
      dimensions,
      imageURLs,
      price,
      title,
      colorPrimary,
      weight,
    } = itemData

    const offerID = this.merchantValues.getMerchantListingValue('offerID') || null
    const listed = this.merchantValues.getMerchantListingValue('listed') || false
    const listedID = this.merchantValues.getMerchantListingValue('listedID') || false
    const sku = (
      this.merchantValues.getMerchantListingValue('privateSKU') ||
      itemData.sku ||
      String(Math.floor(10000000 + Math.random() * 90000000))
    )

    let normalizedData = {
      id,

      offerID,
      listed,
      listedID,

      acceptReturns,
      allowBestOffer,
      buyItNowPrice,
      duration,
      freeShipping,
      handlingTime,
      offerMaximumDecline,
      offerMinimumAccept,
      paymentPolicy,
      paypalEmail,
      pricingFormat,
      returnPolicy,
      shippingPolicy,
      shippingType,
      shipsFrom,
      startingPrice,
      sku,
      upc,

      brand,
      categoryPath,
      categorySpecifics,
      condition,
      description,
      dimensions,
      imageURLs,
      price,
      title,
      weight,
      colorPrimary,
    }

    const handleListingErrors = (errors) => {
      const errorFragments = errors.map((e, idx) => {
        if (!e || !e.message) {
          return false
        }
        return <FormError nonBlocking={true} key={`error${idx}`}>{e.message}</FormError>
      }).filter(Boolean)

      console.log('Got errors when listing on eBay: ', errors)
      return this.setState({
        listing: false,
        errorFragments: errorFragments,
      })
    }

    console.log('Listing: ', normalizedData)

    try {
      const result = await listEbay(normalizedData)

      if (!result.listedID) {
        return handleListingErrors(result)
      }

      // SEAN should do this on backend function
      // may take longer to render on client if mutation done on backend instead of the local client firestore lib??
      updateItem(this.props.item.id, {
        merchants: {
          ebay: {
            dateCreated: new Date(),
            dateLastModified: new Date(),
            listed: true,
            listedID: result.listedID,
            listingURL: `https://www.ebay.com/itm/${result.listedID}`,
            offerID: result.offerID || null,
            privateSKU: normalizedData.sku,
          },
        }
      }).then(updatedValues => {

        this._updateItem(updatedValues, {
          listing: false,
          updating: false,
          errorFragments: [],
        }, true)
        console.log('Updated item!')
      })
    }
    catch (errors) {
      return handleListingErrors(errors)
    }
  }

  onSubmitUpdate = async () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })
      return
    }

    this.setState({
      updating: true,
    })

    this.listItem()
  }

  onSubmitList = async () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })
      return
    }

    this.setState({
      listing: true,
    })

    this.listItem()
  }

  onSubmitDelist = async () => {

    const itemID = this.props.item.id
    const offerID = this.merchantValues.getMerchantListingValue('offerID')
    if (!offerID) {
      return this.setState({
        errorFragments: [
          <FormError nonBlocking={true} key={`error`}>Error delisting: no eBay offer ID found for listing</FormError>,
        ],
      })
    }

    this.setState({
      delisting: true,
    })

    try {
      await delistEbay(offerID, itemID)

      updateItem(this.props.item.id, {
        merchants: {
          ebay: {
            listed: false,
          },
        }
      }).then(updatedValues => {

        this._updateItem(updatedValues, {
          delisting: false,
          errorFragments: [],
        }, true)
        console.log('Updated item - delisted!')
      })
    }
    catch (e) {
      return this.setState({
        delisting: false,
        errorFragments: [
          <FormError nonBlocking={true} key={`error`}>{e.message || 'Unknown error while delisting on eBay'}</FormError>,
        ],
      })

    }
  }

  render() {
    const { classes, merchantName } = this.props
    const { item, errorFragments } = this.state
    const listedBanner = (
      <ItemFormListedBanner
        merchantName={merchantName}
        item={item}
        onList={this.onSubmitList}
        onDelist={this.onSubmitDelist}
        onUpdate={this.onSubmitUpdate}
        isListing={this.state.listing}
        isDelisting={this.state.delisting}
        isUpdating={this.state.updating}
        isListingDisabled={
          errorFragments &&
          errorFragments.length > 0 &&
          errorFragments.find(f => !f.props || !f.props.nonBlocking)
        }
        saveButton={
          <Button disabled={this.state.saving} variant="contained" color="primary" type="submit">
            {this.state.saving ? 'Saving..' : 'Save item' }
          </Button>
        }
      />
    )
    return (
      <div className={classes.formRoot} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <ItemFormHeader
            merchantName={this.props.merchantName}
            item={item}
          />
          <form  ref={this.formRef} onSubmit={this.onSubmit.bind(this)}>

            {
              this.merchantValues.getMerchantListingValue('listed', false) && listedBanner
            }

            <Paper className={`${classes.formSection} ${classes.formSectionFirst} ${classes.rootEbay}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPhotos number={1} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormImages
                    merchantName={this.props.merchantName}
                    imageURLs={this.merchantValues.get('imageURLs', [])}
                  />
                </Grid>
              </Grid>{/* Photos */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEbay}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderDetails number={2} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTitle
                    maxCharacters={MAX_CHARACTERS_TITLE}
                    defaultTitle={this.merchantValues.get('title', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item md={12} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputDescription
                    defaultDescription={this.merchantValues.get('description', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item md={12} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputBrand
                        merchantName={this.props.merchantName}
                        defaultBrand={this.merchantValues.get('brand', null)}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                    <Grid item sm={6} md={6}>
                      <ItemFormInputCondition
                        defaultCondition={this.merchantValues.get('condition', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                  </Grid>
                </Grid>{/* Brand / Condition */}

                <Grid item md={12} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputColor
                        labelText={'Color'}
                        defaultColor={this.merchantValues.get('colorPrimary', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>{/* Color */}

                <Grid item md={12} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={3} style={{ marginTop: 0 }}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputSku
                        optional
                        defaultValue={this.merchantValues.get('sku', '')}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <InputLabel optional>UPC</InputLabel>
                      <TextFieldWithSavedNote
                        name="upc"
                        defaultValue={this.merchantValues.get('upc', '')}
                        onChange={this.onFormChange}
                        fullWidth
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Grid>{/* SKU */}
              </Grid>{/* Item Details */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEbay}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderCategory number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputCategories
                    merchantName={this.props.merchantName}
                    item={this.props.item}
                    defaultCategoryPath={this.merchantValues.get('categoryPath', [])}
                    onChange={this.onFormChange}
                    hidden={this.props.hidden}
                  />
                </Grid>
              </Grid>{/* Category */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEbay}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPackage text={'Shipping'} number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormEbayShipping
                    policyOptions={this.state.ebayPolicies}
                    defaultZipCode={this.merchantValues.get('shipsFrom', Store.getDefault('shipsFrom', ''))}
                    defaultFulfillmentPolicy={this.merchantValues.get('shippingPolicy', Store.getDefault('shippingPolicy', ''))}
                    defaultPaymentPolicy={this.merchantValues.get('paymentPolicy', Store.getDefault('paymentPolicy', ''))}
                    defaultReturnPolicy={this.merchantValues.get('returnPolicy', Store.getDefault('returnPolicy', ''))}
                    defaultFreeShipping={this.merchantValues.get('freeShipping', false)}
                    defaultShippingService={this.merchantValues.get('shippingService', '')}
                    defaultShippingType={this.merchantValues.get('shippingType', '')}
                    defaultHandlingTime={this.merchantValues.get('handlingTime', '')}
                    defaultPaypalEmail={this.merchantValues.get('paypalEmail', '')}
                    defaultAcceptReturns={this.merchantValues.get('acceptReturns', 'ReturnsAccepted')}
                    onChange={this.onFormChange}
                  />
                  <Grid container direction="row" style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <ItemFormInputWeight
                        merchantName={this.props.merchantName}
                        defaultWeight={this.merchantValues.get('weight', { lb: 0, oz: 0 })}
                        onChange={this.onFormChange}
                        merchantValues={this.merchantValues}
                      />
                      <ItemFormInputDimensions
                        merchantName={this.props.merchantName}
                        defaultValues={this.merchantValues.get('dimensions', [0,0,0])}
                        merchantValues={this.merchantValues}
                        onChange={this.onFormChange}
                      />

                    </Grid>{/* Package Weight / Dimensions */}
                  </Grid>
                </Grid>
              </Grid>{/* Shipping */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEbay}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPrice number={4} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormEbayPricing
                    defaultPrice={this.merchantValues.get('buyItNowPrice', this.merchantValues.get('price', ''))}
                    defaultOfferMinimumAccept={this.merchantValues.get('offerMinimumAccept', '')}
                    defaultOfferMaximumDecline={this.merchantValues.get('offerMaximumDecline', '')}
                    defaultAllowBestOffer={this.merchantValues.get('allowBestOffer', false)}
                    pricingFormat={this.merchantValues.get('pricingFormat', 'FixedPriceItem')}
                    onChange={this.onFormChange}
                  />
                </Grid>
              </Grid>{/* Pricing & Renewal Policy */}
            </Paper>

            { errorFragments && errorFragments.length > 0 &&
              <FormValidationErrors
                errorFragments={errorFragments}
              />
            }

            { listedBanner }
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ItemFormEbay)

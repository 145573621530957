import React, { Fragment } from 'react'

import {
  Button,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import SelectWithSavedNote from './SelectWithSavedNote'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'


const inputIsRequired = (input) => {
  const rules = input.validationRules || {}
  return rules && rules.minValues > 0
}

const inputHasTextField = (input) => {
  const rules = input.validationRules || {}
  return rules && rules.selectionMode === 'FreeText'
}

class ItemFormInputCategorySpecifics extends React.Component {

  constructor(props) {
    super(props)

    // Mapping of option ID -> child category ID
    this.childForOptionIdMap = this._childForOptionIdMap()

    const defaultValues = this._defaultValuesForMerchant(props.defaultValues)

    console.log(`default values for ${props.merchantName} `, defaultValues, props.defaultValues)
    let initialChild = null
    Object.values(defaultValues).forEach(option => {
      if (option && option.id && this.childForOptionIdMap[option.id]) {
        initialChild = this.childForOptionIdMap[option.id]
      }
    })

    this.state = {
      scaleID: null, // Used when there are multiple scales
      hideOptionalFields: props.merchantName === 'etsy' || props.merchantName === 'ebay',

      // Currently used only for Mercari (nested child categories)
      // We're assuming we can only have one visible child at once which may not be correct
      child: initialChild,

      defaultValues,

      values: {}, // input id => option id

      reset: false,
    }

    this.normalizeValueForMerchant = this.normalizeValueForMerchant.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {

    if (

      // The props defaultValues are the autofilled values passed from the parent

      prevProps.defaultValues &&
      Object.keys(prevProps.defaultValues).length === 0 &&

      this.props.defaultValues &&
      Object.keys(this.props.defaultValues).length > 0
    ) {

      // We have default autofill values for the first time
      const defaultValues = this._defaultValuesForMerchant(this.props.defaultValues)

      if (Object.keys(defaultValues) > Object.keys(this.state.defaultValues)) {
        // SEAN TODO investigate do we also have to update the initialChild property??
        this.setState({
          defaultValues,
          reset: true,
        }, () => {
          this.setState({
            reset: false,
          })
        })
      }

    }
  }

  normalizeValueForMerchant(option, scaleId, defaultValue) {
    const merchantName = this.props.merchantName

    switch (merchantName) {
      case 'poshmark':
        // If our default value for poshmark has the long/short ids,
        // ensure our normalized value has matching size_obj keys
        if (
          defaultValue &&
          defaultValue.long !== undefined &&
          defaultValue.short !== undefined
        ) {
          return JSON.stringify({
            size_obj: option,
            size_set_tags: [ scaleId ],
          })
        } else {
          // Limit to basic keys (poshmark API)
          return JSON.stringify({
            size_obj: {
              display: option.display,
              display_with_size_set: option.display_with_size_set,
              id: option.id,
            },
            size_set_tags: [ scaleId ],
          })
        }
      default:
        return JSON.stringify({
          display: option.display,
          id: option.id,
        })
    }
  }

  _defaultValuesForMerchant(defaultValues = {}) {
    const { merchantName, item } = this.props
    if (!merchantName ||
        !item ||
        !item.merchants ||
        !item.merchants[merchantName] ||
        !item.merchants[merchantName].marketplaceSpecifics ||
        !item.merchants[merchantName].marketplaceSpecifics.categorySpecifics) {
      return defaultValues
    }
    let values = Object.assign({}, defaultValues)
    values = Object.assign(values, item.merchants[merchantName].marketplaceSpecifics.categorySpecifics)
    return values
  }

  _childForOptionIdMap() {
    let map = {}
    Object.values(this.props.data).forEach(cat => {
      Object.values(cat.scales || {}).forEach(scale => {
        Object.values(scale.options || {}).forEach(option => {
          if (option.child) {
            map[option.id] = option.child
          }
        })
      })
    })
    return map
  }

  render() {
    const { data = {}, classes, merchantName } = this.props;

    // Sort so that required inputs appear first (optional ones are last)
    const inputs = Object.values(data).sort((inputA, inputB) => {
      const a = inputIsRequired(inputA)
      const b = inputIsRequired(inputB)
      if (a === b) {
        return 0
      }
      if (a && !b) {
        return -1
      }
      return 1
    })


    //console.log('Category specifics: default values', defaultValues, item)

    const buildInput = (input, isChild = false) => {

      if (!input || !input.scales) {
        return null
      }
      const { defaultValues, reset } = this.state
      if (reset) {
        return null
      }

      const scales = Object.values(input.scales)
      // Value is full JSON of option (for poshmark)
      const defaultValue = defaultValues[input.id] ? JSON.stringify(defaultValues[input.id]) : ''
      const isRequired = inputIsRequired(input)

      if (!scales.length) {
        console.log('Sean got no scales')
        return null
      } else if (scales.length === 1) {

        // One scale type. Have just one selector
        const options = Object.values(scales[0].options || {})

        // I'm still figuring out how many options there are here in terms of scales/tags
        // The normalized object has a "size_set_tags" field that includes the scale name (see poshmarkChromeHelpers.js)
        const scaleId = Object.keys(input.scales)[0]

        // SEAN TODO need to finish this
        //
        //
        // We need to filter out the values to those that
        // are valid given the current schema.
        // For example, we could have a "size" specific that applies to a category path, but
        // we have changed the category path and that size is no longer valid. We must identify those
        // and change the default
        // console.log('filter out invalid?', input.id,defaultValue, options)
        // debugger

        return (
          <Fragment>
            <FormControl
              className={classes.conditionControl}
              fullWidth
            >
              <SelectWithSavedNote
                name={`${merchantName}CategorySpecifics[${input.id}]`}
                defaultValue={defaultValue}
                required={isRequired}
                onChange={(e) => {
                  console.log('Set option with one scale!', e.target.value)


                  try {
                    const optionData = JSON.parse(e.target.value)

                    // Assume that there is only one child option for Mercari (not sure if this is correct)
                    // TODO do I need to hide this if we deselected the child?
                    if (optionData.id && this.childForOptionIdMap[optionData.id]) {
                      this.setState({
                        child: this.childForOptionIdMap[optionData.id],
                      })
                    } else if (!isChild && this.state.child && this.state.child[input.id]) {
                      this.setState({
                        child: null,
                      })
                    }
                  } catch (e) {
                    console.error('Error changing cat specific:', e)
                  }
                  if (this.props.onChange) {
                      this.props.onChange()
                  }
                }}
              >
                {
                  options.map(option => {
                    const value = this.normalizeValueForMerchant(option, scaleId, defaultValue)
                    return <MenuItem key={`${option.id}${scaleId}`} value={value}>{option.display}</MenuItem>
                  })
                }
              </SelectWithSavedNote>
            </FormControl>
            {
              // TODO instead of hardcoding sizeId here we want to check if the child corresponds to the selected values
              // our assumption here breaks if there are any other specifics besides the parent and the size options
              // so we should change this
              !isChild && this.state.child && input.id !== 'sizeId' &&
              Object.values(this.state.child).map(input => {
                const isRequired = inputIsRequired(input)
                return (
                  <Fragment key={input.id}>
                    <InputLabel optional={!isRequired}>{input.display}</InputLabel>
                    {/*.This is a hacky div that lets us put data about the categorySpecifics schema in the DOM for us to parse later for form validation */}
                    {/*.This is the worst shit I've ever seen.. eventually use Redux or some shared State object instead but for now I'm sorry */}
                    <input style={{ display: 'none' }} id={`${merchantName}CategorySpecificsSchema[${input.id}]`} data-json={JSON.stringify(input)} />
                    <input style={{ display: 'none' }} id={`${merchantName}CategorySpecificsSchemaChild`} data-json={String(input.id)} />
                    {buildInput(input, true)}
                  </Fragment>
                )
              })
            }
          </Fragment>
        )
      } else {
        console.log('Sean handle multiple scales', scales)

        // For multiple scales, our default scale value is the selected size_set_tag (poshmark only)
        // TODO handle non poshmark
        // TODO handle multiple inputs. Currently it handles only one additional field
        // scaleID should be changed to a map of { categorySpecificID: scaleID }
        let defaultScale = this.state.scaleID || ''
        if (
          merchantName === 'poshmark' &&
          defaultValues[input.id] &&
          defaultValues[input.id].size_set_tags &&
          defaultValues[input.id].size_set_tags.length
        ) {
          defaultScale = defaultValues[input.id].size_set_tags[0]
        }

        // This handles scales with multiple options (targeted given etsy system)
        // Unlike poshmark, we don't save the scale ID on the backend yet explicitly, so we need to find the corresponding
        // option within the scale schema
        // Example: categorySpecificsEtsy/443 inseam in/cm + all options
        if (defaultValues[input.id]) {

          const scale = scales.find(scale => {
            const optionValue = defaultValues[input.id]
            return optionValue && optionValue.id && scale.options && Object.values(scale.options).find(option => option.id === optionValue.id) !== undefined
          })
          if (scale && scale.id) {
            defaultScale = scale.id
          }
        }

        // This handles when there is a select scale without any options
        // For Etsy, sometimes scales exist without options, so we just set the scale's value to the value
        // Example: categorySpecificsEtsy/299 Volume scales
        if (
          defaultValues[input.id] &&
          scales.find(scale => scale.id === defaultValues[input.id]) !== undefined
        ) {
          defaultScale = defaultValues[input.id]
        }

        const options = defaultScale && defaultScale !== '' ? Object.values((scales.find(scale => scale.id === defaultScale) || {}).options || {}) : []
        const isRequired = inputIsRequired(input)
        const hasTextField = inputHasTextField(input)

        console.log('Sean scale + options', defaultScale, options)

        return (
          <Fragment>
            <FormControl
              className={classes.conditionControl}
              fullWidth
            >
              <SelectWithSavedNote
                name={`${merchantName}CategorySpecificsScale[${input.id}]`}
                defaultValue={defaultScale}
                required={isRequired}
                fullWidth
                onChange={(e) => {
                  console.log('Set option with multi scales!', e.target.value)
                  this.setState({ scaleID: e.target.value })
                }}
              >
                {
                  scales.map(scale => {
                    return (
                      <MenuItem
                        key={scale.id}
                        value={scale.id}
                      >{scale.display}</MenuItem>
                    )
                  })
                }
              </SelectWithSavedNote>
            </FormControl>
            { options.length > 0 &&
              <FormControl
                className={classes.conditionControl}
                fullWidth
              >
                <SelectWithSavedNote
                  name={`${merchantName}CategorySpecifics[${input.id}]`}
                  required={isRequired}
                  defaultValue={defaultValue}
                  fullWidth
                  onChange={(e) => {
                    console.log('Set option!', e.target.value)
                  }}
                >
                  {
                    options.map(option => {
                      return <MenuItem key={option.id} value={this.normalizeValueForMerchant(option, defaultScale, defaultValue)}>{option.display}</MenuItem>
                    })
                  }
                </SelectWithSavedNote>
              </FormControl>
            }
            {
              hasTextField &&
              <TextFieldWithSavedNote
                defaultValue='0'
                fullWidth
              />
            }
          </Fragment>
        )
        return null
      }
    }

    const renderInput = (input) => {
      const isRequired = inputIsRequired(input)
      return (
        <Grid item key={input.id} xs={12} sm={6} md={6} lg={6}>
          <InputLabel optional={!isRequired}>{input.display}</InputLabel>
            {/*.This is a hacky div that lets us put data about the categorySpecifics schema in the DOM for us to parse later for form validation */}
            {/*.This is the worst shit I've ever seen.. eventually use Redux or some shared State object instead but for now I'm sorry */}
            <input style={{ display: 'none' }} id={`${merchantName}CategorySpecificsSchema[${input.id}]`} data-json={JSON.stringify({ display: input.display, id: input.id } )} />
            {buildInput(input)}
        </Grid>
      )
    }

    if (this.state.hideOptionalFields) {
      return (
        <Fragment>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            { inputs.filter(input => inputIsRequired(input)).map(input => renderInput(input)) }
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Button
              onClick={() => { this.setState({ hideOptionalFields: false })}}
              variant="outlined"
              style={{
                margin: 10,
              }}
            >
              Show Optional Fields
            </Button>
          </Grid>
        </Fragment>
      )
    }

    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        { inputs.map(input => renderInput(input)) }
      </Grid>
    )
  }

}

/*
  Poshmark:
    {
     "size":{
        "display":"Size",
        "id":"size",
        "validationRules":{
           "maxValues":1,
           "minValues":1,
           "selectionMode":"SelectionOnly"
        },
        "scales":{
           "standard":{
              "display":"Standard",
              "id":"standard",
              "options":{
                 "4":{
                    "display":"4",
                    "display_with_size_set":"4",
                    "id":"4",
                    "long":"4",
                    "short":"4"
                 },
                 ....
              }
           }
        }
      }
    }

    Mercari:

  {
   "sizeId":{
      "display":"Size",
      "id":"sizeId",
      "validationRules":{
         "maxValues":1,
         "minValues":1,
         "selectionMode":"SelectionOnly"
      },
      "scales":{
         "na_scale":{
            "id":"na_scale",
            "options":{
               "0":{
                  "display":"5 (38)",
                  "id":334
               },
               ...
            }
         }
      }
   }
}
*/

export default withStyles(styles)(ItemFormInputCategorySpecifics)


import React from 'react'

import NotesIcon from '@material-ui/icons/Notes'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderExtra = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<NotesIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Extra'}
  />
)

export default ItemFormSectionHeaderExtra

// color variables

const colors = {
  black: 'hsl(220, 20%, 20%)',
  lightGray: 'hsl(220, 20%, 70%)',
  blue: 'hsl(220, 80%, 60%)',

  listingjoy: 'rgb(116,151,240)',
  listingjoyhex: '#7497f0',
  listingjoyShadow: 'rgba(116,151,240, 0.6)',

  successShadow: 'rgba(60, 156, 68, 0.8)',
  success: 'rgb(60, 156, 68)',

  ebay: 'rgb(15, 102, 206)',
  etsy: 'rgb(242, 114, 36)',
  facebook: 'rgb(24,119,242)',
  poshmark: 'rgb(130, 37, 51)',
  mercari: 'rgb(94, 109, 242)',
  tradesy: '#888',

  ebayShadow: 'rgba(15, 102, 206, 0.6)',
  etsyShadow: 'rgba(242, 114, 36, 0.6)',
  facebookShadow: 'rgba(24,119,242, 0.6)',
  poshmarkShadow: 'rgba(130, 37, 51, 0.6)',
  mercariShadow: 'rgba(94, 109, 242, 0.6)',
}

export default colors

import React, { Fragment, useEffect } from 'react'
import {
  HeaderLink,
} from '../../styles/links'

import { 
  Grid,
  Typography,
} from '@material-ui/core';

import NavPills from '../components/NavPills'
import ItemListFilterBar from '../posts/ItemListFilterBar'
import Button from '../components/Button'
import AddIcon from '@material-ui/icons/Add'

import DashboardIcon from '@material-ui/icons/Dashboard'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PersonIcon from '@material-ui/icons/Person'

import {
  Link
} from 'react-router-dom'

const MiniNav = ({ windowWidth, itemCountString, style = {} }) => {

  const pathname = window.location.pathname
  const isGrid = pathname === '/'
  const isOnboarding = pathname.indexOf('/onboarding') === 0
  const isAuth = (
    pathname.indexOf('/login') === 0 || 
    pathname.indexOf('/register') === 0
  )
  const getLogoLink = () => {
    if (isAuth) {
      return '/login'
    }
    if (isOnboarding) {
      return '#'
    }
    return '/'
  }

  const maxWidth = 1100
  const width = Math.floor(Math.min(windowWidth, maxWidth))
  const navWidth = 180 // SEAN todo make this not a constant.. it can change
  const navMobileMargin = Math.floor(navWidth * 4 / 3)
  const windowIsAtLeastMediumWidth = windowWidth > 960

  return (
    <Grid 
      container 
      justify='space-between' 
      align={windowIsAtLeastMediumWidth ? 'flex-start' : 'center' }
    >        
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ paddingTop: 8 }}>
        <HeaderLink to={getLogoLink()}>
          <img src='/img/logo.png' style={{ height: 30, marginRight: 6 }} />
          <span className='listingjoy-logo'>ListingJoy</span>
        </HeaderLink>
        { itemCountString &&
          <div style={{
            textTransform: 'uppercase',
            fontSize: 20,
            marginTop: 2, 
          }}>
            <Typography variant="body2">
              { itemCountString }
            </Typography>
          </div>
        }
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ItemListFilterBar onlySearch={3} />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <NavPills
          color="primary"
          small
          tabs={[
            {
              tabIcon: DashboardIcon,
              path: '/',
            },
            {
              tabIcon: StorefrontIcon,
              path: '/settings',
            },
            {
              tabIcon: PersonIcon,
              path: '/account',
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default MiniNav

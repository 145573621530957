import ReactGA from 'react-ga'

import categoriesItemNamesMercari from '../views/data/categories/mercari-category-name-lookup.json'
import categoriesItemLastNameMercari from '../views/data/categories/mercari-category-last-name-lookup.json'
import categoriesItemNamesPoshmark from '../views/data/categories/poshmark-category-name-lookup.json'
import createItem from './createItem'
import fetchCategorySpecifics from './fetchCategorySpecifics'
import CategoryHelpers from '../views/posts/merchants/helpers/categoryHelpers'

const importItem = async (data) => {

  ReactGA.event({
    category: 'Item',
    action: 'Import item',
  })

  let values = {}
  switch (data.platform) {
    case 'poshmark_api':
      values = await transformDataForPoshmarkAPI(data)
      break
    case 'poshmark':
      values = await transformDataForPoshmark(data)
      break
    case 'mercari':
      values = await transformDataForMercari(data)
      break
    case 'ebay':
      values = transformDataForEbay(data)
      break
    case 'etsy':
      values = transformDataForEtsy(data)
      break
    default:
      console.error('Invalid platform name: ', data.platform)
  }

  console.log('About to call createItem with values: ', values)

  return createItem(values)
}

const transformDataForPoshmarkAPI = (data) => {
  const primaryImageURL = (data.cover_shot || {}).url
  const otherImageURLs = (data.pictures || []).map(p => p.url)
  const colors = (data.colors || []).filter(c => c && c.name).map(c => c.name.toLowerCase())
  const catalog = data.catalog || {}
  const categoryPath = [
    (data.department || {}).id,
    (data.category_v2 || {}).id,
  ].concat((data.category_features || []).map(f => f.id)).filter(Boolean)
  const lastCategoryId = categoryPath.length > 0 ? categoryPath[categoryPath.length - 1] : null
  const firstSize = (
    data.inventory && 
    data.inventory.size_quantities &&
    data.inventory.size_quantities[0]
  )
  const size = firstSize ? {
    size_obj: firstSize.size_obj,
    size_set_tags: firstSize.size_set_tags,
  } : null

  const other = data.seller_private_info || {}

  const result = {
    imageURLs: [ primaryImageURL ].concat(otherImageURLs).filter(Boolean),
    title: data.title || '',
    description: data.description || '',
    brand: data.brand && data.brand.length > 0 ? data.brand : null,
    colorPrimary: colors.length > 0 ? colors[0] : '',
    colorSecondary: colors.length > 1 ? colors[1] : '',
    condition: data.condition === 'nwt' ? 1 : 4,
    categoryPath: categoryPath,
    price: data.price || null,
    originalPrice: data.original_price || null,
    weight: { lb: 0, oz: 0 },
    dimensions: [0, 0, 0],
    sku: other.sku || null,
    merchants: {
      poshmark: {
        dateCreated: new Date(),
        dateLastModified: new Date(),
        listed: true,
        listedID: data.id,
        listingURL: data.permalink || '',
        type: 'listing',
        marketplaceSpecifics: {
          categorySpecificsSchemaID: lastCategoryId,
          categorySpecifics: {
            size: size,
          },
          originalPrice: data.original_price || null,
          otherInfo: other.other_info || '',
          costPrice: other.cost_price_amount && other.cost_price_amount.val ? +other.cost_price_amount.val : 0,
        },
        overrides: {
          categoryPath: categoryPath,
          sku: other.sku || null,
        },
      },
    },
  }
  return result
}

const transformDataForPoshmark = async (data) => {
  const categoryNames = data.categoryNames || []
  const categoryPath = [
    categoryNames.length > 0 ? categoriesItemNamesPoshmark[categoryNames[0]] : undefined,
    categoryNames.length > 1 ? categoriesItemNamesPoshmark[`${categoryNames[0]}|${categoryNames[1]}`] : undefined,
    categoryNames.length > 2 ? categoriesItemNamesPoshmark[`${categoryNames[0]}|${categoryNames[1]}|${categoryNames[2]}`] : undefined,
  ].filter(Boolean)


  const lastCategoryId = categoryPath.length > 0 ? categoryPath[categoryPath.length - 1] : null


  let categorySpecificsData = null
  console.log('About to fetch category specifics.')
  try {
    categorySpecificsData = await fetchCategorySpecifics('poshmark', lastCategoryId, 3000 /* 3 sec timeout */)
  }
  catch (e) {
    console.error('Unable to fetch category specifics')
  }
  console.log('Fetched category specifics', categorySpecificsData)

  const getSizeData = (categorySpecifics, sizeName) => {
    if (!sizeName || !sizeName.length) {
      return null
    }

    const scales = Object.keys(categorySpecifics.size.scales || {})

    const sizeMatchForScale = (scale) => {
      if (
        !categorySpecifics || 
        !categorySpecifics.size || 
        !categorySpecifics.size.scales || 
        !categorySpecifics.size.scales[scale] || 
        !categorySpecifics.size.scales[scale].options
      ) {
        return null
      }

      const sizeOptions = Object.values(categorySpecifics.size.scales[scale].options)
      const sizeMatch = sizeOptions.find(data => {
        return (
          data.display && 
          data.display_with_size_set === sizeName
        )
      })

      if (!sizeMatch) {
        return null
      }

      return {
        size_obj: sizeMatch,
        size_set_tags: [ scale ],
      }
    }

    for (let i = 0; i < scales.length; i++) {
      const scale = scales[i]
      const result = sizeMatchForScale(scale)
      if (result) {
        return result
      }
    }

    return null
  }

  const size = categorySpecificsData ?
    getSizeData(categorySpecificsData, data.sizeName) :
    null

  return {
    imageURLs: data.imageURLs || [],
    title: data.title || '',
    description: data.description || '',
    brand: data.brand && data.brand.length > 0 ? data.brand : null,
    colorPrimary: data.colors && data.colors.length > 0 ? data.colors[0] : '',
    colorSecondary: data.colors && data.colors.length > 1 ? data.colors[1] : '',
    condition: data.condition === 'nwt' ? 1 : 4,
    categoryPath: categoryPath,
    price: data.price || null,
    originalPrice: data.originalPrice || null,
    weight: { lb: 0, oz: 0 },
    dimensions: [0, 0, 0],
    sku: data.sku || null,
    merchants: {
      [data.platform]: {
        dateCreated: new Date(),
        dateLastModified: new Date(),
        listed: true,
        listedID: data.platformID,
        listingURL: `https://poshmark.com/listing/${data.platformID}`,
        type: 'listing',
        marketplaceSpecifics: {
          categorySpecificsSchemaID: lastCategoryId,
          categorySpecifics: {
            size: size,
          },
          originalPrice: data.originalPrice || null,
          otherInfo: data.otherInfo || '',
          costPrice: data.costPrice || 0,
        },
        overrides: {
          categoryPath: categoryPath,
          sku: data.sku || null,
        },
      },
    },
  }
}

const transformDataForMercari = async (data) => {

  const categoryNames = data.categoryNames || []
  // Note: cast to string
  let categoryPath = [
    categoryNames.length > 0 ? categoriesItemNamesMercari[categoryNames[0]] : undefined,
    categoryNames.length > 1 ? categoriesItemNamesMercari[`${categoryNames[0]}|${categoryNames[1]}`] : undefined,
    categoryNames.length > 2 ? categoriesItemNamesMercari[`${categoryNames[0]}|${categoryNames[1]}|${categoryNames[2]}`] : undefined,
  ].filter(Boolean).map(i => String(i))

  if (categoryPath.length === 0 && categoryNames.length > 0) {

    // No path in full lookup table. Use the "last" name lookup table
    const lastCategoryName = categoryNames[categoryNames.length - 1]
    const lastCategoryNameResult = categoriesItemLastNameMercari[lastCategoryName]
    if (lastCategoryNameResult && lastCategoryNameResult.length) {

      const lastCategoryKey = lastCategoryNameResult[0]
      const lookupTable = CategoryHelpers.lookupTableForMerchant(data.platform)
      const lookupValue = lookupTable[String(lastCategoryKey)] // Mercari has string keys
      if (lookupValue && lookupValue.categoryPath) {

        categoryPath = lookupValue.categoryPath
      }
    }
  }


  let poshmarkCategoryPath = []
  if (categoryPath.length > 0) {
    const categoryKey = categoryPath[categoryPath.length - 1]
    poshmarkCategoryPath = CategoryHelpers.poshmarkCategoryPathForMerchantKey(categoryKey, data.platform)
  }

  let condition = 4
  switch (data.condition) {
    case 'new':
      condition = 1
      break
    case 'like new':
      condition = 2
      break
    case 'good':
      condition = 3
      break
    case 'fair':
      condition = 4
      break
    case 'poor':
      condition = 5
      break
    default:
      break
  }

  let categorySpecifics = {}
  if (categoryPath.length > 0 && data.size && data.size !== '---') {
    let categorySpecificsData = null
    console.log('About to fetch category specifics.')
    try {
      categorySpecificsData = await fetchCategorySpecifics('mercari', categoryPath[categoryPath.length - 1], 3000 /* 3 sec timeout */)

      console.log('Fetched category specifics', categorySpecificsData)
      if (
        categorySpecificsData && 
        categorySpecificsData.sizeId && 
        categorySpecificsData.sizeId.scales &&
        categorySpecificsData.sizeId.scales.na_scale &&
        categorySpecificsData.sizeId.scales.na_scale.options
      ) {
        const options = Object.values(categorySpecificsData.sizeId.scales.na_scale.options || {})
        const match = options.find(option => option.display === data.size)
        if (match) {
          categorySpecifics = {
            sizeId: {
              display: match.display,
              id: match.id,
            }
          }
        }
      }
    }
    catch (e) {
      console.error('Unable to fetch category specifics', e)
    }

    console.log('After try/catch block category specifics', categorySpecificsData)
  }


  return {
    imageURLs: data.imageURLs || [],
    categoryPath: poshmarkCategoryPath || [],
    title: data.title || '',
    description: data.description || '',
    brand: data.brand || '',  
    condition: condition,
    price: data.price || null,
    weight: data.weight || { lb: 0, oz: 0 },
    dimensions: data.dimensions || [0, 0, 0],
    merchants: {
      [data.platform]: {
        dateCreated: new Date(),
        dateLastModified: new Date(),
        listed: true,
        listedID: data.platformID,
        listingURL: `https://www.mercari.com/us/item/${data.platformID}/`,
        type: 'listing',
        marketplaceSpecifics: {
          categorySpecifics: categorySpecifics,
          shipsFrom: data.shipsFrom || null,
          tags: data.tags || [],
          size: data.size || null,
        },
        overrides: {
          categoryPath: categoryPath,
        },
      },
    },
  }
}
const transformDataForEbay = (data) => {
  
}
const transformDataForEtsy = (data) => {
  
  let weight = { lb: 0, oz: 0 }
  if (data.item_weight_unit === 'oz') {
    let ozs = +data.item_weight || 0
    weight = { lb: Math.floor(ozs / 16), oz: (ozs % 16) }
  }

  return {
    imageURLs: data.imageURL ? [ data.imageURL ] : [], // TODO handle multiple images
    title: data.title || '',
    description: data.description || '',
    colorPrimary: '', // TODO likely need to get attribute 
    colorSecondary: '', // TODO likely need to get attribute
    condition: data.condition === 'nwt' ? 1 : 4,
    categoryPath: [], // TODO map etsy path to LJ path
    price: +data.price || null,
    weight: weight,
    dimensions: [+data.item_length || 0, +data.item_width || 0, +data.item_height || 0],
    merchants: {
      [data.platform]: {
        dateCreated: new Date(),
        dateLastModified: new Date(),
        listed: data.state === 'active',
        listedID: data.listing_id,
        listingURL: data.url || '',
        type: 'listing',
        marketplaceSpecifics: {
          tags: data.tags || [],
          listingState: data.state || '',
          whoMade: data.who_made || '',
          whenMade: data.when_made || '',
          shippingProfile: data.shipping_template_id || '',
          recipient: data.recipient || '',
          occasion: data.occasion || '',
        },
        overrides: {
          categoryPath: data.category_path_ids || [],
        },
      },
    },
  }
}

export default importItem

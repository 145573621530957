
import React from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './form/ItemFormStyles'
import BadgeLabel from '../base/BadgeLabel'

import ItemHelpers from '../../item'
import MerchantHelpers from '../../merchant'

const ItemFormHeader = ({ loading, merchantName, item, classes }) => (
  <Grid
    container
    direction="row"
    justify="space-between"
  >
    <Grid item>
      <Typography variant="h1" className={classes.title}>
        {
          loading ?
            'Loading..' :
              (merchantName ?
                (ItemHelpers.isListedForMerchant(item, merchantName) ?
                  `${MerchantHelpers.merchantNameFormatted(merchantName)} Listing` :
                  `List on ${MerchantHelpers.merchantNameFormatted(merchantName)}`
                ) :
                'Item Details'
              )
        }
      </Typography>
    </Grid>
    <Grid item>
      { loading &&
        <BadgeLabel loading={loading} text={loading ? 'Loading..' : ItemHelpers.listingStateForMerchant(item, merchantName)} />
      }
    </Grid>
  </Grid>
)

export default withStyles(styles)(ItemFormHeader)

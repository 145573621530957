import React, { Fragment } from 'react'

import {
  Select,
  FormHelperText,
} from '@material-ui/core';

class SelectWithSavedNote extends React.Component {

  constructor(props) {
    super(props)

    const { children, defaultValue, required, value, loading } = props
    const initialValue = defaultValue || value || ''

    const hasError = !loading && required && children.map(i => i && i.props && String(i.props.value)).indexOf(String(initialValue)) === -1
    this.state = {
      value: initialValue,
      initialValue: initialValue,
      hasError,
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate(prevProps) {

    if (prevProps.value !== this.props.value) {
      const { children, defaultValue, required, value, loading } = this.props
      const hasError = !loading && required && children.map(i => i && i.props && String(i.props.value)).indexOf(String(value)) === -1
      if (hasError !== this.state.hasError) {
        this.setState({
          hasError,
        })
      }
    }
  }

  onChange = function(e) {
    this.setState({ 
      value: e.target.value,
      hasError: false,
    })

    // sean TODO this is the hackiest shit but currently the Autocomplete onChange callback happens BEFORE category values
    // are available in the DOM (which is how we validate the form). So add this timeout here
    // so that the values are available when we trigger our onChange callback
    setTimeout(() => {
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }, 0)
  }

  render() {  
    const { value, loading, style, inputProps, children } = this.props

    // TODO add left padding -6px 
    const marginLeftFix = -8
    const selectStyles = {
      background: 'rgb(245, 245, 245)',
      padding: '2px 5px',
      marginTop: -7,
      marginLeft: marginLeftFix,
      ...(style || {}),
    }

    return (
      <Fragment>
        <Select
          {...this.props}
          style={selectStyles}
          loading={String(loading)}
          onChange={this.onChange}
          inputProps={{
            className: loading ? 'shine' : '',
            disabled: loading,
            ...(inputProps || {}),
          }}
          error={this.props.hasError || this.state.hasError}
        >
          {children}
        </Select>
{/*        { this.state.initialValue !== this.state.value && 
          <FormHelperText 
            style={{ 
              marginLeft: marginLeftFix,
            }}
          >Not Saved</FormHelperText> 
        }*/}
      </Fragment>
    )
  }

}

export default SelectWithSavedNote
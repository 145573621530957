import React from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'

const ItemFormSectionHeader = ({ number, icon, text, classes }) => (
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <Typography variant="h2" className={classes.formSectionHeaderText}>
      { icon &&
        <span style={{ marginRight: 10 }}>
          {icon}
        </span>
      }
      {
        false && number && `${String(number)}. `
      }
      {text}
    </Typography>
    <hr style={{
      marginTop: 0,
      marginBottom: '2em',
      color: '#333',
    }} />
  </Grid>
)

export default withStyles(styles)(ItemFormSectionHeader)

import React from 'react'

import { 
  InputLabel,
} from '@material-ui/core';

const CustomInputLabel = ({ children, style = {}, optional = false }) => (
  <InputLabel style={{
    paddingRight: 3,
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 600,
    ...style,
  }}>
    {children}
    {optional ? 

      <span style={{
        color: 'gray',
        marginLeft: 4,
        fontWeight: 500,
        fontSize: 12,
      }}>(Optional)</span> :

      <span style={{
        color: 'red',
        marginLeft: 4,
      }}>
        *
      </span>
    }

  </InputLabel>
)

export default CustomInputLabel
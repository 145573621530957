
import React from 'react'
// Instead of having a model class with Item helper methods,
// just put everything in here
import FormError from './views/base/FormValidationError'
import CategoryHelpers from './views/posts/merchants/helpers/categoryHelpers'

const Item = {

  itemFormErrorFragments: (data) => {
    return [
      (!data.imageURLs || data.imageURLs.length === 0) && <FormError key={'images'} selector='#images'>Must supply at least one image</FormError>,
      (!data.brand || data.brand.length === 0) && <FormError key={'brand'} selector='#brand'>Must fill out brand</FormError>,
      (!data.colorPrimary) && <FormError key={'colorPrimary'} selector='#colorPrimary'>Must fill out primary color</FormError>,
      (!data.categoryPath || data.categoryPath.length === 0) && <FormError key={'category'} selector='#categoryPath'>Must fill out category</FormError>,
      (data.categoryPath && data.categoryPath.length > 0 && !CategoryHelpers.isCategoryPathCompleteForMerchant(data.categoryPath, 'poshmark')) && <FormError key={'category'} selector='#categoryPath'>Must finish filling out category</FormError>,
      //(!data.weight || (data.weight.lb === 0 && data.weight.oz === 0)) && <FormError key={'weight1'} selector='#weight'>Must fill out weight</FormError>,
      //(!data.dimensions || data.dimensions.filter(d => +d === 0).length > 0) && <FormError key={'dimensions'} selector='#dimensions'>Must supply all dimensions</FormError>,
      (!data.price) && <FormError key={'price'} selector='#price'>Must fill out list price</FormError>,
    ].filter(Boolean)
  },

  listingStateForListingJoy: (item) => {
    if (!item) {
      return 'Not Saved'
    }
    if (
      Item.itemFormErrorFragments(item).length > 0 ||
      !item.title ||
      !item.description
    ) {
      return 'In Progress'
    }
    return 'Complete'
  },

  listingStateForMerchant: (item, merchantName = 'listingjoy') => {
    if (merchantName.toLowerCase() === 'listingjoy') {
      return Item.listingStateForListingJoy(item)
    }
    if (!item || !item.merchants || !item.merchants[merchantName]) {
      return 'not_listed'
    }
    const merchantData = item.merchants[merchantName]
    if (merchantData.sold) {
      return 'sold'
    }
    if (!merchantData.listed && (merchantData.listingURL || merchantData.listedID)) {
      return 'delisted'
    }
    if (!merchantData.listed) {
      return 'not_listed'
    }
    return 'listed'
  },

  isListedForMerchant: (item, merchantName) => {
    const state = Item.listingStateForMerchant(item, merchantName)
    return state === 'listed' || state === 'sold'
  },

  isSold: (item) => {
    if (!item || !item.merchants) {
      return false
    }
    const merchantValues = Object.values(item.merchants)
    for (let values of merchantValues) {
      if (values.sold) {
        return true
      }
    }
    return false
  }
}

export default Item

import React from 'react'

import ItemForm from './ItemForm'
import ItemFormEbay from './merchants/ItemFormEbay'
import ItemFormEtsy from './merchants/ItemFormEtsy'
import ItemFormFacebook from './merchants/ItemFormFacebook'
import ItemFormMercari from './merchants/ItemFormMercari'
import ItemFormPoshmark from './merchants/ItemFormPoshmark'
import ItemFormTradesy from './merchants/ItemFormTradesy'

import Config from '../../config'

const ItemForms = (props) => {
  if (!props.match) {
    return <ItemForm {...props}/>
  }

  if (!props.item) {
    // Loading state, show empty form
    return (
      <ItemForm
        loading={true}
        {...props}
      />
    )
  }
  
  return (
    <div className={!props.item ? 'form-loading' : ''}> {/* form-loading class is not working.. TODO get it to work */}
      <ItemForm 
        {...props}
        loading={!props.item}
        hidden={props.match.params.selectedMerchant && props.match.params.selectedMerchant !== 'listingjoy'}
      />

      { Config.merchantsAvailable.ebay &&
        <ItemFormEbay 
          {...props}
          hidden={props.match.params.selectedMerchant !== 'ebay'}
          merchantName='ebay'
        />
      }
      { Config.merchantsAvailable.etsy &&
        <ItemFormEtsy 
          {...props}
          hidden={props.match.params.selectedMerchant !== 'etsy'}
          merchantName='etsy'
        />
      }
      { Config.merchantsAvailable.poshmark &&
        <ItemFormPoshmark 
          {...props}
          hidden={props.match.params.selectedMerchant !== 'poshmark'}
          merchantName='poshmark'
        />
      }
      { Config.merchantsAvailable.mercari &&
        <ItemFormMercari 
          {...props}
          hidden={props.match.params.selectedMerchant !== 'mercari'}
          merchantName='mercari'
        />
      }
      { Config.merchantsAvailable.facebook &&
        <ItemFormFacebook
          {...props}
          hidden={props.match.params.selectedMerchant !== 'facebook'}
          merchantName='facebook'
        />
      }
      { Config.merchantsAvailable.tradesy &&
        <ItemFormTradesy
          {...props}
          hidden={props.match.params.selectedMerchant !== 'tradesy'}
          merchantName='tradesy'
        />
      }
    </div>
  )
}

export default ItemForms

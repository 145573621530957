// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import Iframe from 'react-iframe'
import { Link } from 'react-router-dom'

import styles from '../form/ItemFormStyles'
import ItemFormHeader from '../ItemFormHeader'
import ItemFormListedBanner from '../ItemFormListedBanner'
import FormFillIframeButton from '../form/FormFillIframeButton'

import ItemFormSectionHeaderPhotos from '../form/headers/ItemFormSectionHeaderPhotos'
import ItemFormSectionHeaderDetails from '../form/headers/ItemFormSectionHeaderDetails'
import ItemFormSectionHeaderCategory from '../form/headers/ItemFormSectionHeaderCategory'
import ItemFormSectionHeaderExtra from '../form/headers/ItemFormSectionHeaderExtra'
import ItemFormSectionHeaderPrice from '../form/headers/ItemFormSectionHeaderPrice'

import ItemFormMerchantValues from './helpers/ItemFormMerchantValues'
import ItemSidebarMerchantListItem from '../ItemSidebarMerchantListItem'

import ItemFormImages from '../form/ItemFormImages'
import ItemFormInputBrand from '../form/ItemFormInputBrand'
import ItemFormInputCategories from '../form/ItemFormInputCategoriesRecursive'
import ItemFormInputColor from '../form/ItemFormInputColor'
import ItemFormInputCondition from '../form/ItemFormInputCondition'
import ItemFormInputTitle from '../form/ItemFormInputTitle'
import ItemFormInputDescription from '../form/ItemFormInputDescription'
import ItemFormPoshmarkPrice from '../form/poshmark/ItemFormPoshmarkPrice'
import ItemFormPoshmarkAdditionalDetails from '../form/poshmark/ItemFormPoshmarkAdditionalDetails'

import ItemListerPoshmark from '../../account/ItemListerPoshmark'

import FormValidationErrors from '../../base/FormValidationErrors'
import FormError from '../../base/FormValidationError'

import CategoryHelpers from '../merchants/helpers/categoryHelpers'

import Merchant from '../../../merchant'
import Store from '../../../store'
import { Message, EE, Events } from '../../../message'

import {
  poshmarkChromeItemFromData,
} from './helpers/poshmarkChromeHelpers'

import {
  ListingJoyExternalEvent,
  PoshmarkEvent,
} from '../../../constants'

class ItemFormPoshmark extends React.Component {

  constructor(props) {
    super(props)

    if (props.item) {
      this.state = {
        item: props.item,
        initialFiles: props.item.imageURLs,
        merchantValues: new ItemFormMerchantValues(props.item, props.merchantName),
      }
    } else {
      this.state = {}
    }

    this._updateItem = this._updateItem.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmitList = this.onSubmitList.bind(this)
    this.onSubmitDelist = this.onSubmitDelist.bind(this)
    this.onReceiveListingItemResponse = this.onReceiveListingItemResponse.bind(this)
    this.onReceiveDelistingItemResponse = this.onReceiveDelistingItemResponse.bind(this)

    this.formRef = React.createRef()

    this.TITLE_MAX_CHARS = 50
    this.DESC_MAX_CHARS = 500
  }

  componentDidMount() {
    EE.addListener(Events.ItemListerPoshmark.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.addListener(Events.ItemDelisterPoshmark.DID_DELIST, this.onReceiveDelistingItemResponse)
    EE.addListener(Events.ItemSidebarMerchantListItem.DELIST_POSHMARK, this.onSubmitDelist)

    if (this.formRef.current) {
      this.onFormChange()
    }
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemListerPoshmark.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.removeListener(Events.ItemDelisterPoshmark.DID_DELIST, this.onReceiveDelistingItemResponse)
    EE.removeListener(Events.ItemSidebarMerchantListItem.DELIST_POSHMARK, this.onSubmitDelist)
  }

  componentDidUpdate() {
    const { hidden, item, merchantName } = this.props
    if (!hidden && item && (!item.merchants || !item.merchants[merchantName] || !item.merchants[merchantName].listed)) {
      EE.emitEvent(Events.ItemListerPoshmark.INITIALIZE)
    }
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  onReceiveListingItemResponse = (data, updatedValues = null) => {
    console.log('In react received data for listing item!!', data)
    if (!data) {
      return this.setState({
        listing: false,
      })
    }

    const errorFragments = (data.errors || []).map((error, idx) => <FormError nonBlocking={true} key={idx}>{error.message || 'Unknown error listing to Poshmark. Ensure you have filled out all fields, try refreshing your connection, and try again.'}</FormError>)
    if (errorFragments.length > 0) {
      return this.setState({
        listing: false,
        errorFragments: errorFragments,
      })
    }

    if (updatedValues) {
      this._updateItem(updatedValues, {
        listing: false,
        errorFragments: [],
      }, true)
    }
  }

  onReceiveDelistingItemResponse = (data, updatedValues, itemID) => {
    console.log('Received delisting item response', data, updatedValues, itemID)

    if (!data || !data.success) {
      const errorFragments = (data.errors || [{ message: 'Unknown error delisting from Poshmark'}]).map((error, idx) => <FormError nonBlocking={true} key={idx}>{error.message || 'Unknown error listing to Poshmark. Ensure you have filled out all fields, try refreshing your connection, and try again.'}</FormError>)
      return this.setState({
        delisting: false,
        errorFragments: errorFragments,
      })
    }

    if (updatedValues) {
      this._updateItem(updatedValues, {
        delisting: false,
        errorFragments: [],
      }, true)
      console.log('Delisted item!')
    }
  }

  _updateItem(updatedValues, additionalStateValues = {}, notify = false) {
    const updatedItem = this.state.merchantValues.getUpdatedItem(updatedValues, this.state.item)

    if (notify) {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, {
        item: updatedItem,
      })
    }

    this.setState({
      item: updatedItem,
      saving: false,
      merchantValues: new ItemFormMerchantValues(updatedItem, this.props.merchantName),
      ...additionalStateValues,
    })
  }

  _formErrorFragments() {
    const data = this._itemData()
    const itemData = this.state.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props

    const errorFragments = [
      !(Store.getUserDetailsPoshmark() || {}).username && <FormError key={'connection'} selector={`#connection`}>Poshmark is not connected. Go to <Link to='/settings'>Merchant Connections</Link> to link your Poshmark account.</FormError>,
      (!itemData.imageURLs || itemData.imageURLs.length === 0) && <FormError key={'images'} selector={`#${merchantName}images`}>Must supply at least one image</FormError>,
      (!itemData.title || itemData.title.length === 0) && <FormError key={'title'} selector={`#${merchantName}title`}>Must supply a title</FormError>,
      (!itemData.description || itemData.description.length === 0) && <FormError key={'description'} selector={`#${merchantName}description`}>Must supply a description</FormError>,
      (!itemData.brand || itemData.brand.length === 0) && <FormError key={'brand'} selector={`#${merchantName}brand`}>Must supply a brand</FormError>,
      (itemData.condition === undefined) && <FormError key={'condition'} selector={`#${merchantName}condition`}>Must supply the item's condition</FormError>,
      (itemData.colorPrimary === undefined) && <FormError key={'colorPrimary'} selector={`#${merchantName}colorPrimary`}>Must supply a primary color</FormError>,

      (!itemData.categoryPath || itemData.categoryPath.length === 0) && <FormError key={'category'} selector={`#${merchantName}categoryPath`}>Must fill out category</FormError>,
      (itemData.categoryPath && itemData.categoryPath.length > 0 && !CategoryHelpers.isCategoryPathCompleteForMerchant(itemData.categoryPath, merchantName)) && <FormError key={'category'} selector='#categoryPath'>Must finish filling out category</FormError>,
      (!itemData.price) && <FormError key={'price'} selector={`#${merchantName}price`}>Must fill out listing price</FormError>,

      // TODO category specifics!
    ]
    .concat(this.state.merchantValues.getCategorySpecificsErrorFragments(this.formRef.current.elements))
    .filter(Boolean)

    return errorFragments
  }

  onSubmit = event => {
    event.preventDefault()
    const errorFragments = this._formErrorFragments()

    this.setState({
      saving: true,
      errorFragments: errorFragments,
    })

    const data = this._itemData()
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitList = () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })
      return
    }
    this.setState({
      listing: true,
    })

    const data = this._itemData()
    const itemData = this.state.merchantValues.getFlattenedData(data)
    const normalizedData = poshmarkChromeItemFromData(itemData)
    const eventData = {
      item: itemData,
      normalizedItem: normalizedData,
      userDetails: Store.getUserDetails(this.props.merchantName),
      merchantName: this.props.merchantName,
    }
    EE.emitEvent(Events.ItemListerPoshmark.CREATE_LISTING, [eventData])

    // Also save:
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitDelist = () => {
    this.setState({
      delisting: true,
    })

    const itemData = this.state.merchantValues.getFlattenedData(this._itemData())
    EE.emitEvent(Events.ItemDelisterPoshmark.DELIST, [itemData])
  }

  onFormFillIframeLoaded = () => {
    const itemData = this.state.merchantValues.getFlattenedData(this._itemData())
    Message.send(PoshmarkEvent.SEND.FILL_NEW_LISTING, itemData)
    console.log(`Dispatching an ${PoshmarkEvent.SEND.FILL_NEW_LISTING} event with in ItemFormPoshmark`)
  }

  _itemData() {
    const elements = this.formRef.current.elements
    const { item } = this.state
    const {
      title,
      description,
      condition,
      colorPrimary,
      colorSecondary,

      originalPrice,
      price,

      sku,
      costPrice,
      otherInfo,

    } = elements

    const merchantValues = {
      title: title.value,
      description: description.value,
      brand: this.state.merchantValues.getBrand(elements),
      condition: condition.value,
      colorPrimary: colorPrimary.value,
      colorSecondary: colorSecondary.value,
      categoryPath: this.state.merchantValues.getCategoryPathNew(elements),
      price: +price.value,

      sku: sku ? sku.value : null,
      costPrice: (costPrice && costPrice.value && costPrice.value.length > 0) ? +costPrice.value : null,
      otherInfo: otherInfo ? otherInfo.value : null,
    }
    const overrides = this.state.merchantValues.buildOverrides(merchantValues, item)
    const categorySpecifics = this.state.merchantValues.getCategorySpecificsValues(elements)

    return {
      merchants: {
        poshmark: {
          type: "listing",
          listedID: null,
          marketplaceSpecifics: {
            originalPrice: +originalPrice.value,
            categorySpecifics: categorySpecifics,
          },
          overrides: overrides,
        },
      },
    };
  }

  render() {
    const { classes } = this.props
    const { item, errorFragments } = this.state
    return (
      <div className={classes.formRoot} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <ItemFormHeader
            merchantName={this.props.merchantName}
            item={item}
          />
          <form ref={this.formRef} onSubmit={this.onSubmit.bind(this)}>

            {
              this.state.merchantValues.getMerchantListingValue('listed', false) ?
              <ItemFormListedBanner
                merchantName={this.props.merchantName}
                item={item}
                isListing={this.state.listing}
                isDelisting={this.state.delisting}
                onList={this.onSubmitList}
                onDelist={this.onSubmitDelist}
              /> : null
            }


            <Paper className={`${classes.formSection} ${classes.formSectionFirst} ${classes.rootPoshmark}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPhotos number={1} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormImages
                    merchantName={this.props.merchantName}
                    imageURLs={this.state.merchantValues.get('imageURLs', [])}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootPoshmark}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderDetails number={2} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTitle
                    maxCharacters={this.TITLE_MAX_CHARS}
                    defaultTitle={this.state.merchantValues.get('title', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputDescription
                    maxCharacters={this.DESC_MAX_CHARS}
                    defaultDescription={this.state.merchantValues.get('description', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputBrand
                        merchantName='poshmark'
                        defaultBrand={this.state.merchantValues.get('brand', null)}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                    <Grid item sm={6} md={6}>
                      <ItemFormInputCondition
                        defaultCondition={this.state.merchantValues.get('condition', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputColor
                        defaultColor={this.state.merchantValues.get('colorPrimary', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputColor
                        defaultColor={this.state.merchantValues.get('colorSecondary', undefined)}
                        onChange={this.onFormChange}
                        secondary
                      />
                    </Grid>
                  </Grid>
                </Grid>{/* Item Details */}
              </Grid>
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootPoshmark}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderCategory number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputCategories
                    merchantName={this.props.merchantName}
                    item={item}
                    defaultCategoryPath={this.state.merchantValues.get('categoryPath', [])}
                    defaultCategorySpecificsSchema={this.state.merchantValues.get('categorySpecificsSchema', null)}
                    onChange={this.onFormChange}
                    hidden={this.props.hidden}
                  />
                </Grid>
              </Grid>{/* Category */}
            </Paper>


            <Paper className={`${classes.formSection} ${classes.rootPoshmark}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPrice number={4} />
                <ItemFormPoshmarkPrice
                  defaultOriginalPrice={this.state.merchantValues.get('originalPrice', 0)}
                  defaultPrice={this.state.merchantValues.get('price', 0)}
                  onChange={this.onFormChange}
                />
              </Grid>{/* Price */}
            </Paper>


            <Paper className={`${classes.formSection} ${classes.rootPoshmark}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderExtra number={5} text='Additional Details (Private)'/>
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormPoshmarkAdditionalDetails
                    defaultSku={this.state.merchantValues.get('sku', '')}
                    defaultCostPrice={this.state.merchantValues.get('costPrice', '')}
                    defaultOtherInfo={this.state.merchantValues.get('otherInfo', '')}
                  />
                </Grid>
              </Grid>{/* Additional Details */}
            </Paper>

            { errorFragments && errorFragments.length > 0 &&
              <FormValidationErrors
                errorFragments={errorFragments}
              />
            }


            <ItemFormListedBanner
              merchantName={this.props.merchantName}
              item={item}
              onList={this.onSubmitList}
              onDelist={this.onSubmitDelist}
              isListing={this.state.listing}
              isDelisting={this.state.delisting}
              isListingDisabled={
                errorFragments &&
                errorFragments.length > 0 &&
                errorFragments.find(f => !f.props || !f.props.nonBlocking)
              }
              saveButton={
                <Button disabled={this.state.saving} variant="contained" color="primary" type="submit">
                  {this.state.saving ? 'Saving..' : 'Save item' }
                </Button>
              }
            />

            { false &&
              <Button
                onClick={() => {
                  window.open(`https://poshmark.com/create-listing?${PoshmarkEvent.SEND.FILL_NEW_LISTING}=1`, '_blank')
                  /*this.openModal.bind(this)*/
                }}
                color="secondary"
                variant="outlined"
              >
                Copy to {Merchant.merchantNameFormatted(this.props.merchantName)}
              </Button>
            }
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ItemFormPoshmark)

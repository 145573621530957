import React from 'react'

const Notice = ({error}) => (
  <div style={{ padding: 50 }}>
    <h1>Notice</h1>
    <p>ListingJoy is undergoing a legal dispute and will be shut down until further notice. We're sorry for the inconvenience.</p>
  </div>
)

export default Notice

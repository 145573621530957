import React from 'react'

import ChromeExtensionAlert from '../base/ChromeExtensionAlert'
import FirebaseAuth from '../misc/FirebaseAuth'
import Error from '../misc/Error'
import Login from '../account/Login'
import createItem from '../../actions/createItem'
import ItemForms from './ItemForms'
import ItemNewImport from './ItemNewImport'
import ItemSidebarMerchantList from './ItemSidebarMerchantList'
import ItemImportAlertPoshmark from './import/ItemImportAlertPoshmark'
import {
  Page,
} from '../../styles/layout'

import {
  Grid,
} from '@material-ui/core'


const ItemNew = ({history, match}) => {
  return (
    <Page>
        <Grid container spacing={3}>
          <ItemSidebarMerchantList
            title="Create Item"
            isNew={true}
            match={match}
          />
          <Grid item xs={12} sm={12} md={9}>
            {
              match.params.poshmarkID ?
                <ItemImportAlertPoshmark
                  id={match.params.poshmarkID}
                  destinationMerchant={match.params.destinationMerchant}
                  history={history}
                /> :
                <ItemNewImport
                  history={history}
                />
            }
            <ChromeExtensionAlert style={{ marginBottom: 30 }}/>
            <FirebaseAuth>
              { ({isLoading, error, auth}) => {

                if (error) {
                  return <Error error={error} />
                }

                if (isLoading) {
                  return <div>loading...</div>
                }

                if (!auth) {
                  return <Login
                    history={history}
                  />
                }

                return <ItemForms
                  onSubmit={values => createItem(values).then(item => history.push(`/item/${item.id}`))}
                  history={history}
                  itemIsNew={true}
                />
              }}
            </FirebaseAuth>
          </Grid>
        </Grid>
    </Page>
  )
}

export default ItemNew

import React, { Fragment } from 'react'

import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InputLabel from '../../../base/InputLabel'
import ItemFormInputPrice from '../ItemFormInputPrice'
import TextFieldWithSavedNote from '../TextFieldWithSavedNote'

import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import ItemFormInputSku from '../ItemFormInputSku'

class ItemFormPoshmarkAdditionalDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      hidden: !props.defaultSku && !props.defaultCostPrice && !props.defaultOtherInfo,
    }
  }

  render() {
    const { defaultSku, defaultCostPrice, defaultOtherInfo, classes } = this.props

    if (this.state.hidden) {
      return (
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Button 
            onClick={() => { this.setState({ hidden: false })}}
            variant="outlined" 
            style={{
              margin: 10,
            }}
          >
            Show Optional Fields
          </Button>
        </Grid>
      )
    }
    return (
      <Fragment>
        <Grid container direction="row" spacing={5}>
          <Grid item sm={6} md={6}>
            <ItemFormInputSku
              optional={true}
              defaultValue={defaultSku}
            />
          </Grid>
          <Grid item sm={6} md={6}>
            <InputLabel optional>Cost Price</InputLabel>
            <TextFieldWithSavedNote 
              name="costPrice"
              defaultValue={defaultCostPrice} 
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                type: 'number',
                min: 0,
              }}
            />      
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5}>
          <Grid item sm={12} md={12}>
            <InputLabel optional>Other Info</InputLabel>
            <TextFieldWithSavedNote 
              multiline={true} 
              fullWidth
              name="otherInfo"
              defaultValue={defaultOtherInfo}
              variant="filled"
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormPoshmarkAdditionalDetails)
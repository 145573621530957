// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React, { Fragment } from 'react'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

//import { useHistory } from 'react-router-dom'

import InputLabel from '../base/InputLabel'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import styles from './form/ItemFormStyles'

import ItemFormHeader from './ItemFormHeader'

import ItemFormSectionHeaderPhotos from './form/headers/ItemFormSectionHeaderPhotos'
import ItemFormSectionHeaderDetails from './form/headers/ItemFormSectionHeaderDetails'
import ItemFormSectionHeaderCategory from './form/headers/ItemFormSectionHeaderCategory'
import ItemFormSectionHeaderPackage from './form/headers/ItemFormSectionHeaderPackage'
import ItemFormSectionHeaderPrice from './form/headers/ItemFormSectionHeaderPrice'

import ItemFormInputBrand from './form/ItemFormInputBrand'
import ItemFormInputCategories from './form/ItemFormInputCategoriesRecursive'
import ItemFormInputColor from './form/ItemFormInputColor'
import ItemFormInputCondition from './form/ItemFormInputCondition'
import ItemFormInputDescription from './form/ItemFormInputDescription'
import ItemFormInputDimensions from './form/ItemFormInputDimensions'
import ItemFormInputDropzone from './form/ItemFormInputDropzone'
import ItemFormInputPrice from './form/ItemFormInputPrice'
import ItemFormInputSku from './form/ItemFormInputSku'
import ItemFormInputTitle from './form/ItemFormInputTitle'
import ItemFormInputWeight from './form/ItemFormInputWeight'

import TextFieldWithSavedNote from './form/TextFieldWithSavedNote'

import ItemHelpers from '../../item'
import Store from '../../store'

import BadgeLabel from '../base/BadgeLabel'
import FormValidationErrors from '../base/FormValidationErrors'

import importItem from '../../actions/importItem'

import { EE, Events } from '../../message'
import {
  MercariEvent,
} from '../../constants'

import ItemFormMerchantValues from './merchants/helpers/ItemFormMerchantValues'

class ItemForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      item: props.item || null,
      loading: false,
      imageURLs: (props.item ? props.item.imageURLs : null) || [],
    }

    this.onFormChange = this.onFormChange.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)

    this.values = new ItemFormMerchantValues(props.item || {})
    this.formRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {

    // Just navigated to a merchant form
    if (
      !prevProps.hidden &&
      this.props.hidden
    ) {
      const { weight, dimensions } = this._itemData()
      EE.emitEvent(Events.ItemForm.DID_NAVIGATE_TO_MERCHANT_FORM, [{ weight, dimensions }])
    }
  }

  async componentDidMount() {
    if (this.formRef.current) {
      this.onFormChange()
    }
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  _itemData() {
    const elements = this.formRef.current.elements
    const {
      title,
      description,
      condition,
      sku,
      colorPrimary,
      colorSecondary,
      weightLbs,
      weightOzs,

      d1,
      d2,
      d3,
      price,
    } = elements

    return {
      title: title.value,
      description: description.value,
      brand: this.values.getBrand(elements),
      condition: condition.value,
      sku: sku.value,
      colorPrimary: colorPrimary.value,
      colorSecondary: colorSecondary.value,
      weight: {
        lb: +weightLbs.value || 0,
        oz: +weightOzs.value || 0,
      },
      categoryPath: this.values && this.values.getCategoryPathNew(elements) || null,
      dimensions: [+d1.value || 0, +d2.value || 0, +d3.value || 0],
      price: +price.value,
      imageURLs: this.state.imageURLs || [],
    }
  }

  _formErrorFragments = () => {
    const data = this._itemData()
    // This helper is needed since this method is used to calculate form "state" elsewhere
    return ItemHelpers.itemFormErrorFragments(data)
  }

  onSubmit = event => {
    event.preventDefault()

    const values = this._itemData()
    const errorFragments = this._formErrorFragments()

    this.setState({
      loading: true,
      errorFragments,
    })

    if (this.props.itemIsNew && errorFragments.length > 0) {
      // If it is a new item, do not persist to DB until validation works
      return this.setState({ loading: false })
    }


    this.props.onSubmit(values).then(updatedValues => {
      const updatedItem = this.values.getUpdatedItem(updatedValues, this.state.item)
      this.setState({

        // TODO sean we are updating the props for this item at the parent level. investigate whether
        // we want to update the item state here at all (it could just be causing perf issues)
        // this is the case for every merchant form as well
        item: updatedItem,
        loading: false,
      })
    })
  }

  onChangeImages = function(imageURLs) {
    console.log('Image URLs: ', imageURLs)
    this.setState({
      imageURLs,
    })
    this.onFormChange()
  }

  onChangeImageFileObjects(fileObjects) {
    // Image data base64 string useful for uploading images via chrome extension
    Store.setImageFileObjects(fileObjects)
  }

  onClickDeleteItem() {
    if (window.confirm(`Are you sure you want to delete "${this.state.item.title || ''}"?`)) {
      this.props.onDelete()
    }
  }

  render() {
    const { classes, loading } = this.props
    const { item } = this.state
    const listingState = ItemHelpers.listingStateForListingJoy(item)
    return (
      <div className={classes.formRoot} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <ItemFormHeader
            loading={loading}
            item={item}
          />
          <form
            onSubmit={this.onSubmit.bind(this)}
            ref={this.formRef}
          >
            <Paper className={`${classes.formSection} ${classes.formSectionFirst}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPhotos number={1} />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ItemFormInputDropzone
                    initialFiles={this.state.imageURLs}
                    onChangeImages={this.onChangeImages.bind(this)}
                    onChangeImageFileObjects={this.onChangeImageFileObjects.bind(this)}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.formSection}>
              <Grid container direction="row">
                <ItemFormSectionHeaderDetails number={2} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTitle
                    defaultTitle={item ? item.title : ''}
                    loading={loading}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputDescription
                    defaultDescription={item ? item.description : ''}
                    loading={loading}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputBrand
                        defaultBrand={item ? item.brand : null}
                        loading={loading}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                    <Grid item sm={6} md={6}>
                      <ItemFormInputCondition
                        defaultCondition={item ? item.condition : undefined}
                        loading={loading}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>

                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputColor
                        defaultColor={item ? item.colorPrimary : undefined}
                        loading={loading}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputColor
                        defaultColor={item ? item.colorSecondary : undefined}
                        loading={loading}
                        onChange={this.onFormChange}
                        secondary
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={5} className={classes.rowContent}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputSku
                        defaultValue={item ? item.sku : ''}
                        loading={loading}
                        onChange={this.onFormChange}
                        optional
                      />
                    </Grid>
                    <Grid item sm={6} md={6} />
                  </Grid>
                </Grid>{/* SKU */}
              </Grid>{/* Item Details */}
            </Paper>

            <Paper className={classes.formSection}>
              <Grid container direction="row">
                <ItemFormSectionHeaderCategory number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  {/* use the poshmark categories by default, even in the generic form */}
                  <ItemFormInputCategories
                    merchantName=''
                    defaultCategoryPath={item ? item.categoryPath : []}
                    loading={loading}
                    onChange={this.onFormChange}
                  />
                </Grid>
              </Grid>{/* Category */}
            </Paper>

            <Paper className={classes.formSection}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPackage number={4} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputWeight
                    defaultWeight={(item && item.weight) || { lb: 0, oz: 0 }}
                    loading={loading}
                    onChange={this.onFormChange}
                    merchantValues={this.values}
                    optional
                  />
                  <ItemFormInputDimensions
                    defaultValues={item && item.dimensions ? item.dimensions : [0, 0, 0]}
                    loading={loading}
                    onChange={this.onFormChange}
                    merchantValues={this.values}
                    optional
                  />
                </Grid>
              </Grid>{/* Package Details */}
            </Paper>

            <Paper className={classes.formSection}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPrice number={5} />
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel>Listing Price</InputLabel>
                  <Grid container direction="row">
                    <ItemFormInputPrice
                      defaultPrice={item ? item.price : ''}
                      loading={loading}
                      onChange={this.onFormChange}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </Grid>{/* Price */}
            </Paper>

            <Grid container direction="row">

              { this.state.errorFragments && this.state.errorFragments.length > 0 &&
                <FormValidationErrors
                  errorFragments={this.state.errorFragments}
                />
              }

              <Grid
                container
                direction="row"
                style={{ marginTop: 50 }}
                justify='flex-end'
              >
                <Grid item>

                  <Button disabled={this.state.loading} variant="contained" color="primary" type="submit">
                    {this.state.loading ? 'Saving..' : 'Save item' }
                  </Button>
{/*                  {
                    this.props.onDelete ?
                      <Button
                        onClick={this.onClickDeleteItem.bind(this)}
                        variant="outlined"
                        color="secondary"
                        className={classes.deleteButton}
                      >
                        Delete item
                      </Button>
                    : null
                  }*/}
                </Grid>
              </Grid>
            </Grid>

            { false && item &&
              <Fragment>
                <hr />
                <Grid container spacing={4}>
                  <Grid item>
                    <Typography variant="body2">
                      Listing Status
                    </Typography>
                    <BadgeLabel text={listingState} />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      Created
                    </Typography>
                    <Typography variant="body1">
                      {item.createdOn ? moment(item.createdOn.seconds * 1000).format('MMM Do, h:mma') : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      Last Saved
                    </Typography>
                    <Typography variant="body1">
                      {item.updatedOn ? moment(item.updatedOn.seconds * 1000).format('MMM Do, h:mma') : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>
            }
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ItemForm)

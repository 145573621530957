// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'

import {
  Button,
  Grid,
  FormHelperText,
  Paper,
  Typography,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import styles from '../form/ItemFormStyles'
import { Link } from 'react-router-dom'

import ItemFormSectionHeaderPhotos from '../form/headers/ItemFormSectionHeaderPhotos'
import ItemFormSectionHeaderDetails from '../form/headers/ItemFormSectionHeaderDetails'
import ItemFormSectionHeaderCategory from '../form/headers/ItemFormSectionHeaderCategory'
import ItemFormSectionHeaderPackage from '../form/headers/ItemFormSectionHeaderPackage'
import ItemFormSectionHeaderPrice from '../form/headers/ItemFormSectionHeaderPrice'
import ItemFormSectionHeaderRenewal from '../form/headers/ItemFormSectionHeaderRenewal'

import ItemFormHeader from '../ItemFormHeader'
import ItemFormListedBanner from '../ItemFormListedBanner'
import ItemFormImages from '../form/ItemFormImages'
import ItemFormInputCategories from '../form/ItemFormInputCategoriesRecursive'
import ItemFormInputColor from '../form/ItemFormInputColor'
import ItemFormInputTags from '../form/ItemFormInputTags'
import ItemFormInputTitle from '../form/ItemFormInputTitle'
import ItemFormInputDescription from '../form/ItemFormInputDescription'
import ItemFormInputDimensions from '../form/ItemFormInputDimensions'
import ItemFormInputPrice from '../form/ItemFormInputPrice'
import ItemFormInputWeight from '../form/ItemFormInputWeight'

import ItemFormMerchantValues from './helpers/ItemFormMerchantValues'
import ItemSidebarMerchantListItem from '../ItemSidebarMerchantListItem'

import ItemFormEtsyColor from '../form/etsy/ItemFormEtsyColor'
import ItemFormEtsyListingState from '../form/etsy/ItemFormEtsyListingState'
import ItemFormEtsyRenewalOptions from '../form/etsy/ItemFormEtsyRenewalOptions'
import ItemFormEtsyShippingProfile from '../form/etsy/ItemFormEtsyShippingProfile'
import ItemFormEtsyWhatIs from '../form/etsy/ItemFormEtsyWhatIs'
import ItemFormEtsyWhenMade from '../form/etsy/ItemFormEtsyWhenMade'
import ItemFormEtsyWhoMade from '../form/etsy/ItemFormEtsyWhoMade'

import FormValidationErrors from '../../base/FormValidationErrors'
import FormError from '../../base/FormValidationError'
import CategoryHelpers from '../merchants/helpers/categoryHelpers'

import fetchUser from '../../../actions/fetchUser'
import delistEtsy from '../../../actions/delistEtsy'
import listEtsy from '../../../actions/listEtsy'
import updateItem from '../../../actions/updateItem'

import Store from '../../../store'
import { Message, EE, Events } from '../../../message'
import {
  ListingJoyExternalEvent,
} from '../../../constants'

class ItemFormEtsy extends React.Component {

  constructor(props) {
    super(props)

    this.userInfo = Store.getUserInfo || {}
    const etsyConnected = this.userInfo.etsyConnected || false
    console.log('etsy connected in constructor:', etsyConnected)

    if (props.item) {
      this.state = {
        item: props.item,

        initialFiles: props.item.imageURLs,
        shippingProfiles: this.userInfo.etsyShippingProfiles || [],
        errorFragments: [],
        etsyConnected: etsyConnected,
      }
    } else {
      this.state = {
        etsyConnected: etsyConnected,
      }
    }

    console.log('init shipping prof: ', this.state.shippingProfiles)

    this.merchantValues = new ItemFormMerchantValues(props.item, props.merchantName)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmitList = this.onSubmitList.bind(this)
    this.onSubmitDelist = this.onSubmitDelist.bind(this)

    this._updateItem = this._updateItem.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)
    this._itemData = this._itemData.bind(this)

    this.formRef = React.createRef()
  }

  async componentDidMount() {
    console.log('Fetching shipping profs')

    EE.addListener(Events.ItemSidebarMerchantListItem.DELIST_ETSY, this.onSubmitDelist)

    if (this.formRef.current) {
      this.onFormChange()
    }
    if (!this.state.etsyConnected || !this.state.shippingProfiles || this.state.shippingProfiles.length === 0) {

      const userInfo = await fetchUser()
      this.userInfo = userInfo || {}

      console.log('user info: ', this.userInfo)

      const shippingProfiles = this.userInfo.etsyShippingProfiles || []
      const etsyConnected = this.userInfo.etsyConnected || false

      console.log('refresh shipping prof: ', shippingProfiles)

      this.setState({
        shippingProfiles,
        etsyConnected,
      }, () => {
        // Need to wait for the state update before updating form
        this.setState({
          errorFragments: this._formErrorFragments(),
        })
      })
    }
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemSidebarMerchantListItem.DELIST_ETSY, this.onSubmitDelist)
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  _updateItem(updatedValues, additionalStateValues = {}, notify = false) {
    const updatedItem = this.merchantValues.getUpdatedItem(updatedValues, this.state.item)

    if (notify) {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, {
        item: updatedItem,
      })
    }
    this.merchantValues = new ItemFormMerchantValues(updatedItem, this.props.merchantName)
    this.setState({
      item: updatedItem,
      saving: false,
      ...additionalStateValues
    })
  }

  _itemData() {
    const { item } = this.state
    const elements = this.formRef.current.elements
    const {
      title,
      description,

      etsyColorPrimary,
      etsyColorSecondary,

      whenMade,
      whatIs,
      whoMade,
      shippingProfile,
      listingState,
      renewalOption,

      price,
    } = elements

    const merchantValues = {
      title: title.value,
      description: description.value,
      etsyColorPrimary: +etsyColorPrimary.value || undefined,
      etsyColorSecondary: +etsyColorSecondary.value || undefined,
      categoryPath: this.merchantValues.getCategoryPathNew(elements),
      dimensions: this.merchantValues.getDimensionsArray(elements),
      price: +price.value,
      weight: this.merchantValues.getWeight(elements),
    }
    const marketplaceSpecifics = {
        whenMade: whenMade.value,
        whatIs: whatIs.value,
        whoMade: whoMade.value,
        tags: this.merchantValues.getTagsArray(elements),
        // For this select element, the value isn't immediately available in the DOM
        // since you can't deselect the shipping profile completely, we can fallback to the default if it exists
        shippingProfile: shippingProfile ? shippingProfile.value : Store.getDefault('shippingProfile', null),
        listingState: listingState.value,
        renewalOption: renewalOption.value,
        categorySpecifics: this.merchantValues.getCategorySpecificsValues(elements),
    }
    return {
      merchants: {
        etsy: {
          type: "listing",
          marketplaceSpecifics: marketplaceSpecifics,
          overrides: this.merchantValues.buildOverrides(merchantValues, item),
        },
      },
    }
  }

  _formErrorFragments() {
    const data = this._itemData()
    const itemData = this.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props
    const { etsyConnected } = this.state
    const categoryPath = data.merchants[merchantName].overrides.categoryPath

    return [
      !etsyConnected && <FormError key={'connection'} selector={`#connection`}>Etsy is not connected. Go to <Link to='/settings'>Merchant Connections</Link> to link your Etsy account.</FormError>,
      (!itemData.imageURLs || itemData.imageURLs.length === 0) && <FormError key={'images'} selector={`#${merchantName}images`}>Must supply at least one image</FormError>,
      (!categoryPath || categoryPath.length === 0) && <FormError key={'category'} selector={`#${merchantName}categoryPath`}>Must fill out category</FormError>,
      (categoryPath && categoryPath.length > 0 && !CategoryHelpers.isCategoryPathCompleteForMerchant(categoryPath, merchantName)) && <FormError key={'category'} selector='#categoryPath'>Must finish filling out category</FormError>,
      (!itemData.weight || (itemData.weight.lb === 0 && itemData.weight.oz === 0)) && <FormError key={'weight1'} selector={`#${merchantName}weightLbs`}>Must fill out weight</FormError>,
      (!itemData.dimensions || itemData.dimensions.filter(d => +d === 0).length > 0) && <FormError key={'dimensions'} selector='#dimensions'>Must supply all dimensions</FormError>,
      (!itemData.price) && <FormError key={'price'} selector={`#${merchantName}price`}>Must fill out list price</FormError>,

       // Specific to etsy
      !itemData.whoMade && <FormError key={'whoMade'} selector='#whoMade'>Must answer "Who Made it?"</FormError>,
      !itemData.whatIs && <FormError key={'whatIs'} selector='#whatIs'>Must answer "What is it?"</FormError>,
      !itemData.whenMade && <FormError key={'whenMade'} selector='#whenMade'>Must answer "When was it Made?"</FormError>,
      !itemData.shippingProfile && <FormError key={'shippingProfile'} selector='#shippingProfile'>Must provide a shipping profile</FormError>,
      !itemData.renewalOption && <FormError key={'renewalOption'} selector='#renewalOption'>Must supply a renewal option</FormError>,
      !itemData.listingState && <FormError key={'listingState'} selector='#listingState'>Must supply a listing state</FormError>,

    ].filter(Boolean)
  }

  onSubmit = event => {
    event.preventDefault()

    const errorFragments = this._formErrorFragments()
    this.setState({
      saving: true,
      errorFragments: errorFragments,
    })

    const data = this._itemData()
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitList = async () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })
      return
    }

    this.setState({
      listing: true,
    })

    const data = this._itemData()

    // Save item in parallel to listing it:
    this.props.onSubmit(data)


    const itemData = this.merchantValues.getFlattenedData(data)
    const {
      brand,
      categoryPath,
      categorySpecifics,
      etsyColorPrimary,
      etsyColorSecondary,
      condition,
      description,
      dimensions,
      imageURLs,
      price,
      title,
      weight,
      listingState,
      tags,
      whenMade,
      whoMade,
      shippingProfile,

      // optional, default hidden fields
      recipient,
      occasion,
    } = itemData

    //recipient: data.recipient || null, // enum(men, women, unisex_adults, teen_boys, teen_girls, teens, boys, girls, children, baby_boys, baby_girls, babies, birds, cats, dogs, pets, not_specified)
    //occasion: null, // enum(anniversary, baptism, bar_or_bat_mitzvah, birthday, canada_day, chinese_new_year, cinco_de_mayo, confirmation, christmas, day_of_the_dead, easter, eid, engagement, fathers_day, get_well, graduation, halloween, hanukkah, housewarming, kwanzaa, prom, july_4th, mothers_day, new_baby, new_years, quinceanera, retirement, st_patricks_day, sweet_16, sympathy, thanksgiving, valentines, wedding)

    const normalizedData = {
      brand,
      categoryPath,
      categorySpecifics,
      etsyColorPrimary,
      etsyColorSecondary,
      condition,
      description,
      dimensions,
      imageURLs,
      price,
      title,
      weight,
      listingState,
      tags,
      whenMade,
      whoMade,
      shippingProfile,

      // optional, default hidden fields
      recipient,
      occasion,

      // computed based on other item fields
      shouldAutoRenew: itemData.renewalOption === 'automatic',
      isSupply: itemData.whatIs === 'supply',
    }

    console.log('Listing: ', normalizedData)

    try {
      const result = await listEtsy(normalizedData)
      if (!result.listing_id) {
        console.log('Got result without listing id: ', result)
        return this.setState({
          listing: false,
          errorFragments: [
            <FormError nonBlocking={true} key="error">{result.message || 'Unknown error while listing on Etsy'}</FormError>,
          ],
        })
      }

      const listingID = result.listing_id

      let draftListingURL = null
      if (
        this.userInfo &&
        this.userInfo.etsyShops &&
        this.userInfo.etsyShops.length > 0 &&
        this.userInfo.etsyShops[0].shop_name) {
        draftListingURL = `https://www.etsy.com/your/shops/${this.userInfo.etsyShops[0].shop_name}/tools/listings/${listingID}`
      }

      // SEAN should do this on backend function
      // may take longer to render on client if mutation done on backend instead of the local client firestore lib??
      updateItem(this.props.item.id, {
        merchants: {
          etsy: {
            dateCreated: new Date(),
            dateLastModified: new Date(),
            listed: true,
            listedID: listingID,
            draftListingURL: draftListingURL,
            draft: listingState === 'draft',
            listingURL: `https://www.etsy.com/listing/${listingID}`,
          },
        }
      }).then(updatedValues => {

        this._updateItem(updatedValues, {
          listing: false,
          errorFragments: [],
        }, true)
        console.log('Updated item!')
      })
    }
    catch (e) {

      console.log('Got error when listing on etsy: ', e)
      return this.setState({
        listing: false,
        errorFragments: [
          <FormError nonBlocking={true} key="error2">{e.message || 'Unknown error while listing on Etsy'}</FormError>,
        ],
      })
    }

  }

  onSubmitDelist = async () => {

    const listingID = this.merchantValues.getMerchantListingValue('listedID')
    if (!listingID) {
      return this.setState({
        errorFragments: [
          <FormError nonBlocking={true} key='error'>Error delisting: no Etsy listingID found</FormError>,
        ],
      })
    }

    this.setState({
      delisting: true,
    })

    try {
      await delistEtsy(listingID)

      updateItem(this.props.item.id, {
        merchants: {
          etsy: {
            listed: false,
          },
        }
      }).then(updatedValues => {

        this._updateItem(updatedValues, {
          delisting: false,
          errorFragments: [],
        }, true)
        console.log('Updated item - delisted!')
      })
    }
    catch (e) {
      return this.setState({
        delisting: false,
        errorFragments: [
          <FormError nonBlocking={true} key='error'>{e.message || 'Unknown while delisting on Etsy'}</FormError>,
        ],
      })

    }
  }

  render() {
    const { classes } = this.props
    const { item, errorFragments } = this.state
    return (
      <div className={classes.formRoot} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <ItemFormHeader
            merchantName={this.props.merchantName}
            item={item}
          />
          <form ref={this.formRef} onSubmit={this.onSubmit.bind(this)}>

            {
              this.merchantValues.getMerchantListingValue('listed', false) ?
              <ItemFormListedBanner
                merchantName={this.props.merchantName}
                item={item}
                isListing={this.state.listing}
                isDelisting={this.state.delisting}
                onList={this.onSubmitList}
                onDelist={this.onSubmitDelist}
              /> : null
            }


            <Paper className={`${classes.formSection} ${classes.formSectionFirst} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPhotos number={1} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormImages
                    merchantName={this.props.merchantName}
                    imageURLs={this.merchantValues.get('imageURLs', [])}
                  />
                </Grid>
              </Grid>
            </Paper>


            <Paper className={`${classes.formSection} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderDetails number={2} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTitle
                    maxCharacters={50}
                    defaultTitle={this.merchantValues.get('title', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputDescription
                    maxCharacters={500}
                    defaultDescription={this.merchantValues.get('description', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormEtsyColor
                        defaultEtsyColor={this.merchantValues.get('etsyColorPrimary')}
                        defaultColor={this.merchantValues.get('colorPrimary')}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <ItemFormEtsyColor
                        defaultEtsyColor={this.merchantValues.get('etsyColorSecondary')}
                        defaultColor={this.merchantValues.get('colorSecondary', undefined)}
                        onChange={this.onFormChange}
                        secondary
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormEtsyWhoMade
                        defaultState={this.merchantValues.get('whoMade', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <ItemFormEtsyWhatIs
                        defaultState={this.merchantValues.get('whatIs', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6} style={{ paddingTop: 0 }}>
                      <ItemFormEtsyWhenMade
                        defaultState={this.merchantValues.get('whenMade', undefined)}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ marginTop: 10 }}>
                    <ItemFormInputTags
                      merchantName={this.props.merchantName}
                      defaultTags={this.merchantValues.get('tags', [])}
                      onChange={this.onFormChange}
                      maxTags={13}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderCategory number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputCategories
                    merchantName={this.props.merchantName}
                    item={item}
                    defaultCategoryPath={this.merchantValues.get('categoryPath', [])}
                    onChange={this.onFormChange}
                    hidden={this.props.hidden}
                  />
                </Grid>
              </Grid>{/* Category */}
            </Paper>


            <Paper className={`${classes.formSection} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPackage number={4} text='Shipping' />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={6} md={6}>
                      <ItemFormEtsyShippingProfile
                        defaultValue={this.merchantValues.get('shippingProfile', Store.getDefault('shippingProfile', ''))}
                        data={this.state.shippingProfiles}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputWeight
                        merchantName={this.props.merchantName}
                        defaultWeight={this.merchantValues.get('weight', { lb: 0, oz: 0 })}
                        onChange={this.onFormChange}
                        merchantValues={this.merchantValues}
                      />
                      <ItemFormInputDimensions
                        merchantName={this.props.merchantName}
                        defaultValues={this.merchantValues.get('dimensions', [0,0,0])}
                        onChange={this.onFormChange}
                        merchantValues={this.merchantValues}
                      />
                    </Grid>{/* Package Weight / Dimensions */}
                  </Grid>
                </Grid>
              </Grid>{/* Shipping */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPrice number={5} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={12} md={12}>
                      <InputLabel>Listing Price</InputLabel>
                      <ItemFormInputPrice
                        defaultPrice={this.merchantValues.get('price', '')}
                        onChange={this.onFormChange}
                      />
                      <FormHelperText>Price you are selling your item</FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>{/* Price */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootEtsy}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderRenewal number={6} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <ItemFormEtsyListingState
                        defaultValue={this.merchantValues.get('listingState', Store.getDefault('listingState', 'draft'))}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <ItemFormEtsyRenewalOptions
                        defaultValue={this.merchantValues.get('renewalOption', Store.getDefault('renewalOption', 'manual'))}
                        onChange={this.onFormChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={3}>
                    <Alert severity="info" style={{ marginTop: 30, marginBottom: 20 }}>
                      Etsy charges $0.20 to list an item (the listing is active for 4 months) and a 5% fee when an item sells.
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            { errorFragments.length > 0 &&
              <FormValidationErrors
                errorFragments={errorFragments}
              />
            }

            <ItemFormListedBanner
              merchantName={this.props.merchantName}
              item={item}
              onList={this.onSubmitList}
              onDelist={this.onSubmitDelist}
              isListing={this.state.listing}
              isDelisting={this.state.delisting}
              isListingDisabled={
                errorFragments &&
                errorFragments.length > 0 &&
                errorFragments.find(f => !f.props || !f.props.nonBlocking)
              }
              saveButton={
                <Button disabled={this.state.saving} variant="contained" color="primary" type="submit">
                  {this.state.saving ? 'Saving..' : 'Save item' }
                </Button>
              }
            />
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ItemFormEtsy)

// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React, { Fragment, useState, useEffect } from 'react'
import Login from '../account/Login'
import FirebaseAuth from '../misc/FirebaseAuth'
import {
  HeaderLink,
} from '../../styles/links'

import { 
  Grid,
  Typography,
} from '@material-ui/core'

import {
  Link
} from 'react-router-dom'
import ChromeExtensionAlert from '../base/ChromeExtensionAlert'

import Button from '../components/Button'
import AddIcon from '@material-ui/icons/Add'

import SettingsIcon from '@material-ui/icons/Settings'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import NavPills from '../components/NavPills'
import ItemListFilterBar from '../posts/ItemListFilterBar'

import DashboardIcon from '@material-ui/icons/Dashboard'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PersonIcon from '@material-ui/icons/Person'

import styled from 'styled-components'
import windowSize from 'react-window-size'
import { EE, Events } from '../../message'
import MiniNav from './MiniNav'

const HeaderFooterWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: max-content auto max-content;
  min-height: 100vh;
`
const Header = styled.div`
  padding: 2rem 1rem 1rem;
`
const Page = styled.div`
  padding: 1rem;
`
const Footer = styled.div`
  padding: 1rem;
  text-align: center;
  opacity: .3;
`

const Layout = ({children, filterBarRoute, windowWidth }) => {

  const [itemCountString, setItemCountString] = useState(null);
  const [showMiniNav, setShowMiniNav] = useState(null);

  useEffect(() => {
    function handleItemCountStringChange(string) {
      console.log('Got item count!!!!', string)
      setItemCountString(string)
    }
    function handleShouldUpdateMiniNavVisibility(value) {
      console.log('Show nav?', value)
      setShowMiniNav(value)
    }
    EE.addListener(Events.ItemList.DID_UPDATE_ITEM_COUNT_STRING, handleItemCountStringChange)
    EE.addListener(Events.ItemList.SHOULD_UPDATE_MINI_NAV_VISIBILITY, handleShouldUpdateMiniNavVisibility)

    return function cleanup() {
      EE.removeListener(Events.ItemList.DID_UPDATE_ITEM_COUNT_STRING, handleItemCountStringChange)
      EE.removeListener(Events.ItemList.SHOULD_UPDATE_MINI_NAV_VISIBILITY, handleShouldUpdateMiniNavVisibility)
    };
  });

  const isGrid = window.location.pathname === '/'
  
  const isOnboarding = window.location.pathname.indexOf('/onboarding') === 0
  const isAuth = (
    window.location.pathname.indexOf('/login') === 0 || 
    window.location.pathname.indexOf('/register') === 0
  )

  const getLogoLink = () => {
    if (isAuth) {
      return '/login'
    }
    if (isOnboarding) {
      return '#'
    }
    return '/'
  }

  const maxWidth = 1100
  const width = Math.floor(Math.min(windowWidth, maxWidth))
  const navWidth = 180 // SEAN todo make this not a constant.. it can change
  const navMobileMargin = Math.floor(navWidth * 4 / 3)
  const windowIsAtLeastMediumWidth = windowWidth > 960

  const header = (
    <Grid container justify='space-between' align={windowIsAtLeastMediumWidth ? 'flex-start' : 'center' }>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        { !isOnboarding &&
          <HeaderLink to={getLogoLink()}>
            <img src='/img/logo.png' style={{ height: 30, marginRight: 6 }} />
            <span className='listingjoy-logo'>ListingJoy</span>
          </HeaderLink>
        }
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {isGrid && <ItemListFilterBar />}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        { (!isOnboarding && !isAuth) &&

          <div style={windowIsAtLeastMediumWidth ? {
            float: 'right',
            marginRight: 15,
          } : {
            marginBottom: 24,
            marginLeft: `calc((100vw - ${navMobileMargin}px) / 2)`,
          }}>
           <NavPills
              color="primary"
              tabs={[
                {
                  tabIcon: DashboardIcon,
                  path: '/',
                },
                {
                  tabIcon: StorefrontIcon,
                  path: '/settings',
                },
                {
                  tabIcon: PersonIcon,
                  path: '/account',
                }
              ]}
            />
          </div>
        } 
      </Grid>
    </Grid>
  )

  if (isGrid) {
    const visible = windowIsAtLeastMediumWidth && showMiniNav
    return (
      <Fragment>
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: windowWidth,
            paddingLeft: `calc((100vw - ${width + 160 /* sean hack. todo calculate better */}px)/ 2)`,
            paddingTop: 0,
            zIndex: 1000,
            backgroundColor: 'rgb(240, 240, 240)',
            display: visible ? 'inherit' : 'none',
            height: 72,
            borderBottom: '1px solid #ddd',
          }}
          className={visible ? 'fade-in-mini-nav' : ''}
        >
          <MiniNav 
            itemCountString={itemCountString}
          />
        </div>
        {children}
      </Fragment>
    )
  }

  return (
    <HeaderFooterWrapper>
      <Header>
        {header}
      </Header>

      {children}

      <Footer>
        { false && !isAuth &&
          <Fragment>© {(new Date()).getFullYear()}</Fragment>
        }
      </Footer>
    </HeaderFooterWrapper>
  )
}

export default windowSize(Layout)

import React, { Fragment } from 'react'

import {
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core'

import {
  Link
} from 'react-router-dom'

import ItemSidebarMerchantListItem from './ItemSidebarMerchantListItem'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import Item from '../../item'
import Config from '../../config'
import Merchant from '../../merchant'
import delistItemForMerchant from '../../actions/delistItemForMerchant'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  backButton: {
    marginBottom: theme.spacing(3),
  },
  subheader: {
    zIndex: 0,
    paddingLeft: 4,
  },
})


class ItemSidebarMerchantList extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isFixed: this.sidebarPositionIsFixed()
    }

    this.onScroll = this.onScroll.bind(this)
    this.onDelistMerchants = this.onDelistMerchants.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll(e) {
    this.setState({
      isFixed: this.sidebarPositionIsFixed()
    })
  }

  onDelistMerchants(merchants) {
    console.log('About to delist for merchants:', merchants)

    const { item } = this.props
    Promise.all(merchants.map(merchantName => {
      return delistItemForMerchant(item, merchantName)
    }))
  }

  sidebarPositionIsFixed() {
    return (
      window.innerWidth >= 960 && // size is not xs/s
      window.scrollY >= 144 // based on size of nav. may need to change this constant if the nav size changes
    )
  }

  render() {
    const { title, item, match, classes, loading, isNew, onChangeListingState } = this.props
    const selectedMerchant = match.params.selectedMerchant
    const itemID = item ? item.id : null
    const listedMerchants = Merchant.listedMerchantsForItem(item)

    const sidebarFragment = (
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          className={this.state.isFixed ? 'ItemSidebarMerchantListItem-fixedPositionSidebar' : ''}
        >

          <Link
            to='/'
            style={{ textDecoration: 'none' }}
          >
            <Button variant="outlined" className={classes.backButton} style={{
              textDecoration: 'none',
            }}>
              <KeyboardBackspaceIcon style={{ marginRight: 10 }}/> Inventory
            </Button>
          </Link>
          <div style={{ marginBottom: 20 }}>
            <Typography variant='h2'>
              {title}
            </Typography>
          </div>
          <List
            component="nav"
          >
            <ItemSidebarMerchantListItem
              selected={!selectedMerchant || selectedMerchant === 'listingjoy'}
              listingState={Item.listingStateForListingJoy(item)}
              itemID={itemID}
              loading={loading}
            />
          </List>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" className={classes.subheader}>
                Listings
              </ListSubheader>
            }
          >
            { Config.merchantsAvailable.ebay &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'ebay'}
                merchantName='ebay'
                listingState={Item.listingStateForMerchant(item, 'ebay')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
            { Config.merchantsAvailable.etsy &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'etsy'}
                merchantName='etsy'
                listingState={Item.listingStateForMerchant(item, 'etsy')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
            { Config.merchantsAvailable.mercari &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'mercari'}
                merchantName='mercari'
                listingState={Item.listingStateForMerchant(item, 'mercari')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
            { Config.merchantsAvailable.poshmark &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'poshmark'}
                merchantName='poshmark'
                listingState={Item.listingStateForMerchant(item, 'poshmark')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
            { Config.merchantsAvailable.facebook &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'facebook'}
                merchantName='facebook'
                listingState={Item.listingStateForMerchant(item, 'facebook')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
            { Config.merchantsAvailable.tradesy &&
              <ItemSidebarMerchantListItem
                selected={selectedMerchant === 'tradesy'}
                merchantName='tradesy'
                listingState={Item.listingStateForMerchant(item, 'tradesy')}
                listedMerchants={listedMerchants}
                itemID={itemID}
                loading={loading}
                disabled={isNew}
                onChangeListingState={onChangeListingState}
                onDelistMerchants={this.onDelistMerchants}
              />
            }
          </List>
        </Grid>
    )
    return (
      <Fragment>
        {/* This is here so that when the main sidebar's position is fixed, it still takes up the same horizontal space */}
        { this.state.isFixed && <Grid item xs={12} sm={3}>{sidebarFragment}</Grid> || sidebarFragment }
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemSidebarMerchantList)

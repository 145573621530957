import colors from '../../../styles/colors'

const styles = theme => ({
  formRoot: {
    padding: theme.spacing(3, 4),
    marginBottom: '2em',
  },
  formSection: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 3),
    boxShadow: `0px 1px 4px ${colors.listingjoyShadow}`,
    background: 'rgb(255, 255, 255)',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.listingjoy,
    borderImage: 'initial',
  },
  formSectionFirst: {
    marginTop: 0,
  },
  formSectionHeaderText: {
    fontSize: 14,
    textTransform: 'uppercase',
    paddingRight: 40,
    color: '#666',
  },
  root: {
    padding: theme.spacing(5, 7),
    boxShadow: `0px 1px 4px ${colors.listingjoyShadow}`,
    marginBottom: '2em',
    background: 'rgb(255, 255, 255)',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.listingjoy,
    borderImage: 'initial',
  },
  rootEbay: {
    boxShadow: `0px 1px 4px ${colors.ebayShadow}`,
    borderColor: colors.ebay,
  },
  rootEtsy: {
    boxShadow: `0px 1px 4px ${colors.etsyShadow}`,
    borderColor: colors.etsy,
  },
  rootFacebook: {
    boxShadow: `0px 1px 4px ${colors.facebookShadow}`,
    borderColor: colors.facebook,
  },
  rootMercari: {
    boxShadow: `0px 1px 4px ${colors.mercariShadow}`,
    borderColor: colors.mercari,
  },
  rootPoshmark: {
    boxShadow: `0px 1px 4px ${colors.poshmarkShadow}`,
    borderColor: colors.poshmark,
  },
  rootTradesy: {
    borderColor: colors.tradesy,
  },
  row: {
    // For non-top row
    marginTop: 25,
  },
  rowContent: {
    paddingTop: 10,
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  h2: {
    marginBottom: theme.spacing(3),
    fontSize: 20,
    paddingRight: 40,
    color: '#666',
  },
  input: {

  },
  inputFullWidth: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  conditionControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inlineInput: {
    display: 'inline',
  },
  connectionRow: {
    // Negative the root padding
    margin: theme.spacing(3, -7),
    padding: 5,
    flexGrow: 1,
    width: `calc(100% + 110px)`,
  },
  deleteButton: {
    float: 'right'
  },
})

export default styles
